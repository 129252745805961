import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { MenuItem } from 'primeng/api';
import { Paginator } from 'primeng/paginator';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { CustomersService } from 'src/app/_services/customers.service';
import { FilterServiceService } from 'src/app/_services/filter-service.service';
import { GlobalService } from 'src/app/_services/global.service';

@Component({
  selector: 'app-customer-purchase-history-detail',
  templateUrl: './customer-purchase-history-detail.component.html',
  styleUrls: ['./customer-purchase-history-detail.component.scss']
})
export class CustomerPurchaseHistoryDetailComponent implements OnInit {
  
  _globals = GlobalService
  page = 0
  rows = 10
  totalRecords = 0
  selectedRow:any;
  dataList: any = []
data =  {
  invoiceNo:'AB12354554',
  date:'01/04/2024',
   totalItem:10,
   discount:120,
   totalPrice:2000,
   pointsRedeemed:120.00,
   invoiceStatus:'Complete',
}
  //order
  orderParam = ''
  orderBy = 'asc'
  @ViewChild('p', { static: false }) paginator: Paginator;
 
  // colheader = [[
  //   'Customer Name', 'phone', 'Email','City', 'Product'
  //   , 'Purchase Count', 'Current Price', 'Total Purchase','Last Purchase Date'
  // ]];

  // colflds = ['customerName', 'phone', 'email', 'city',
  //   'product','purchaseCount','currentPrice', 'totalPurchase', 'date'
  // ];

  constructor(
    public _customerService: CustomersService,
    private _spinner: NgxSpinnerService,
    public _filterService:FilterServiceService,
    public _abd:AppBaseDataService,
  ) { }

  ngOnInit(): void {
    this._globals.componentName = 'Customer Purchase History'
    // this.getDataList()
  this.getDummyList()
  }
  getDummyList() {
    for (let i = 0; i < 50; i++) {
      this.dataList.push({ ...this.data });
    }
  }

 


 


  
  
//   exportExcel() {
//     let data =this.dataList.map((v)=>{
//       return{
//         ...v,product:v.productName+"\n"+v.barcode+"\n"+v.sku
//       }
//      })
//     this._abd.exportExcel(data, this.colheader[0], this.colflds, 'Customer Purchase History Report')
//   }

//   exportPdf() {
//  let data =this.dataList.map((v)=>{
//   return{
//     ...v,product:v.productName+"\n"+v.barcode+"\n"+v.sku
//   }
//  })
//     this._abd.exportPdf(data, this.colheader, this.colflds, 'Customer Purchase History Report',false,false,false)
   
//   }
}
