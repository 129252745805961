import { GlobalService } from 'src/app/_services/global.service';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { BaseService } from './base.service';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ProductsService {
  filter: any = null
  QOHChange=false;
  activeItem: any = null
  productInfo: any = ''
  locations: any = ''
  // Auto PO
  autoPurchaseOrder:boolean =false;
  isNew = false
  isEdit = false
  isViewOnly = false
  isReject = false

  productName: any = 'any name of your choice'
  descriptionUrl: any = ''
  productDescription: any = ''
  productNotes = ''
  sku: any = ''
  barcode: any = ''
//----\\
altBarcode:any[] = []
altSKUs:any[] = []
altTags:any[] = []

labelPrintingImage: any = ""

  validate = { pd: false, pp: false, pi: false }

  selectedProductType: any = ''
  selectedSellAs: any = ''
  selectedGrouping: any = ''
  selectedDiscount: any = ''
  selectedAgeRestriction: any = ''
  selectedGender: any = ''
  selectedLocations: any = []
  selectedLocationsQOH: any = []

  isTrackStock = false
  isAllowNegativeStock = false
  isSellOnline = false
  priceA: any = 12
  priceB: any = 23
  priceC: any = 34
  costPrice: any = 12
  discountPrice: any = 0
  caseQty = 0
  variantAttributes = []
  taxRates: any = []

  isTaxable = false
  isOpenPrice = false
  isPrintAsQR = false
  isReturnable=true

  isNewDepartment = false
  isNewCategory = false
  isNewManufacturer = false
  isNewVendor = false
  printNotesOnReceipt = false
  ShowNotesWhenRingingItem = false

  featureImage: any = null
  thImages: any = []
  thImageNames: any = []

  selectedTaxRate: any = ''
  selectedSellQuantity: any = ''

  defaultPrice = []
  selectedDefaultPrice: any = ''

  //----- shipping information
  selectedShippingWeight: any = ''
  shippingWeight = 0

  selectedShippingDimension: any = ''
  shippingHeight = 0
  shippingWidth = 0
  shippingLength = 0

  //----- tmp data
  pts = {
    t_productName: '',
    descriptionUrl: '',
    sku: '',
    barcode: '',
    priceA: null,
    priceB: null,
    priceC: null,
    costPrice: null,
    discountPrice: null,
    caseQty: null,
    maxLevel: 0,
    minLevel: 0,
    reorderLevel: 0,
    minOrderQty: 0,
    maxOrderQty: 0,
    autoPurchaseOrder:false,
  //  ----
    altBarcode:[],
    altSKUs:[],
    altTags:[],
    itemSKUId:""
  }

  constructor(
    private _bs: BaseService,
    private _abd: AppBaseDataService,
    private _fb: FormBuilder,
    private http: HttpClient
  ) {
    if (!this.productInfo.defaultPrice) {
      this.getProductGeneralInformation().subscribe(res => {
        if (res.success) {
          this.productInfo = JSON.parse(JSON.stringify(res.data))
          this.selectedDefaultPrice = this.productInfo.defaultPrice[0]
          this.selectedTaxRate = this.productInfo.taxRate[0]
          this.selectedSellQuantity = this.productInfo.sellQuantity[0]

          //console.log('PRODUCT-INFO', this.productInfo)
        }
      })
    }

    this.isTaxable = true
    this.pts.sku = this.sku
    this.pts.barcode = this.barcode
    //----\\  


    this.filter = {
      searchText: '',
      selectedProductSellTypeFilter: '',
      selectedDepartment: '',
      selectedCategory: '',
      selectedVendor: '',
      selectedManufacturer: '',
    }
  }

  resetVariables() {
    this.selectedProductType = ''
    this.selectedSellAs = ''
    this.selectedGrouping = ''
    this.selectedDiscount = ''
    this.selectedAgeRestriction = ''
    this.selectedGender = ''
    // ----\\
    this.altBarcode = []
    this.altSKUs = []
    this.altTags = []
    
    this.selectedLocations = []

    this.isTrackStock = false
    this.isAllowNegativeStock = false
    this.isSellOnline = false
    this.priceA = ''
    this.priceB = ''
    this.priceC = ''
    this.costPrice = ''
    this.discountPrice = 0
    this.caseQty = 0
    this.variantAttributes = []

    this.isTaxable = false
    this.isOpenPrice = false
    this.isPrintAsQR = false

    this.isNewDepartment = false
    this.isNewCategory = false
    this.isNewManufacturer = false
    this.isNewVendor = false
    this.printNotesOnReceipt = false
    this.ShowNotesWhenRingingItem = false

    this.featureImage = null
    this.thImages = []

    this.selectedTaxRate = ''
    this.selectedSellQuantity = ''

    this.defaultPrice = []
    this.selectedDefaultPrice = ''

    //----- shipping information
    this.selectedShippingWeight = this._abd.WeightUOM[0]
    this.shippingWeight = 0

    this.selectedShippingDimension = this._abd.DimensionUOM[3]
    this.shippingHeight = 0
    this.shippingWidth = 0
    this.shippingLength = 0
    this.pts.maxLevel = 0;
    this.pts.minLevel = 0;
    this.pts.reorderLevel = 0;
    this.pts.minOrderQty = 0;
    // this.pts.maxOrderQty = 0;
    // this.pts.autoPurchaseOrder = false;
  }

  getProductGeneralInformation() {
    let url = environment.InventoryBaseURL + environment.GeneralDetail;

    return this._bs.httpGet(url).pipe(map(res => {
      return res;
    }))
  }

  saveProduct(model: any) {
    //console.log(model)
    const url = environment.InventoryBaseURL + environment.ItemModule + 'v2'
    return this._bs.httpPost(url, model, true).pipe(map(res => {
      return res
    }))
  }

  updateProduct(model: any, id: any) {
    //console.log(model)
    const url = environment.InventoryBaseURL + environment.ItemModule + 'v2/' + id
    return this._bs.httpPut(url, model, true).pipe(map(res => {
      return res
    }))
  }

  deleteProduct(id: string) {

    let url = environment.InventoryBaseURL + environment.ItemModule + "/" + id;
    return this._bs.httpDelete(url).pipe(map(res => {
      return res;
    }))
  }

  getAllProductList(paras: any) {
    let url = environment.InventoryBaseURL + environment.GetStockStatusReport;

    return this._bs.httpGetParams(url, paras).pipe(map(res => {
      return res
    }))
  }

    searchProductForLoyaltyProgram(paras: any) {
        let url = environment.InventoryBaseURL + environment.ItemModule;

        return this._bs.httpGetParams(url, paras).pipe(map(res => {
            return res
        }))
    }

  getAllProductList4Inventory(paras: any, qryParas = null) {
    let url = environment.InventoryBaseURL + environment.ItemModule;
    if(qryParas) url +=  qryParas;
    return this._bs.httpGetParams(url, paras).pipe(map(res => {
      return res
    }))
  }

  ExportInventoryCSV(paras: any) {

    let url = environment.InventoryBaseURL + environment.ItemModule + "/ExportInventoryItems";
    let _headers = this._bs.GetAuthHeader();
    return this.http.get(url + '?' + paras, { responseType: 'arraybuffer', headers: _headers }).pipe(map(res => {
      return res
    }))
  }

  import(paras: any) {
    const formData = new FormData();
    formData.append('file', paras.file);
    formData.append('locationId', paras.locationid.toString());
    let additPram = "";
    if (paras.importOption !== undefined) {
      additPram = paras.importOption + "=true"
    }
    let url = environment.InventoryBaseURL + environment.ImportInventory + "?" + additPram
    return this._bs.httpPost(url, formData).pipe(map(res => {
      return res
    }))
  }

  searchQuantity(locationId: number, skuCode: string, productId: string) {
    let url = environment.InventoryBaseURL + "GetStockStatus" + "?skucode=" + skuCode +
      "&locationId=" + locationId + "&productId=" + productId;
    return this._bs.httpGet(url).pipe(map(res => {
      return res;
    }))
  }

  transferInventoryPost(data: any) {
    let url = environment.InventoryBaseURL + 'Transfer/StockTransferV2';
    return this._bs.httpPost(url, JSON.stringify(data)).pipe(map(res => {
      return res;
    }))
  }

  inventoryAdjustmentPost(data: any) {
    let url = environment.InventoryBaseURL + 'InventoryAdjustment';
    return this._bs.httpPost(url, JSON.stringify(data)).pipe(map(res => {
      return res;
    }))
  }


  getTransfersList(senderLocation: string, receiverLocation: string,
    startDate: string, endDate: string, transfersStatus: number,
    transfersStatusCheck: number,
    pageNo, pageSize, filterValue = '',isReciveList=false) {
      if(isReciveList){
        let url = environment.InventoryBaseURL + 'Transfer/GetStockTransfersV2'
        + "?locationIdFrom=" + receiverLocation + "&locationIdTo="
        + senderLocation + "&startDate=" + startDate + "&endDate=" + endDate
        + '&TransfersStatusCheck=' + transfersStatusCheck
        + '&filterValue=' + filterValue
        + "&transfersStatus=" + transfersStatus + "&pageNo=" + pageNo + "&pageSize=" + pageSize;
  
      return this._bs.httpGet(url).pipe(map(response => {
        return response;
      }))
      }
      else
      {
        let url = environment.InventoryBaseURL + 'Transfer/GetStockTransfersV2'
        + "?locationIdFrom=" + senderLocation + "&locationIdTo="
        + receiverLocation+ "&startDate=" + startDate + "&endDate=" + endDate
        + '&TransfersStatusCheck=' + transfersStatusCheck
        + '&filterValue=' + filterValue
        + "&transfersStatus=" + transfersStatus + "&pageNo=" + pageNo + "&pageSize=" + pageSize;
  
      return this._bs.httpGet(url).pipe(map(response => {
        return response;
      }))
      }
   
  }

  searchSingleTransferOrder(transferId: any, what: any) {
    let url = environment.InventoryBaseURL + environment.SearchStockTransfer + "/" + transferId
    url = url + '?TransfersStatusCheck=' + what

    return this._bs.httpGet(url).pipe(map(response => {
      return response;
    }))
  }

  viewInventoryAdjustmentDetails(adjustmentId: any) {
    let url = environment.InventoryBaseURL + "InventoryAdjustment" + "/" + adjustmentId
    return this._bs.httpGet(url).pipe(map(response => {
      return response;
    }))
  }
  getInventoryAdjustmentList(data: any) {
    let url = environment.InventoryBaseURL + 'GetInventoryAdjustment';
    return this._bs.httpPost(url, JSON.stringify(data)).pipe(map(res => {
      return res;
    }))
  }

  getItemStockStatus(skuId, locations) {
    let url = `${environment.InventoryBaseURL}GetItemStockStatus?itemSKUId=${skuId}&locationId=${locations}`
    return this._bs.httpGet(url).pipe(map(res => {
      return res;
    }))
  }

  //   getInventoryAdjustmentList(senderLocation: string,
  //     startDate: string, endDate: string,AdjustmentType: String,
  //     pageNo, pageSize, filterValue='') {
  //     let url = environment.InventoryBaseURL + 'GetInventoryAdjustment'
  //       + "?LocationIds=" + senderLocation +  "&startDate=" + startDate + "&endDate=" + endDate
  //       + '&AdjustmentType='+AdjustmentType
  //       + '&filterValue=' + filterValue
  //      + "&pageNo=" + pageNo + "&pageSize=" + pageSize;

  //     return this._bs.httpPost(url).pipe(map(response => {
  //       return response;
  //     }))
  //   }


}
