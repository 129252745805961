import { formatDate } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { MenuItem } from 'primeng/api';
import { Paginator } from 'primeng/paginator';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { BaseService } from 'src/app/_services/base.service';
import { CustomersService } from 'src/app/_services/customers.service';
import { FilterServiceService } from 'src/app/_services/filter-service.service';
import { GlobalService } from 'src/app/_services/global.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-customer-purchase-history',
  templateUrl: './customer-purchase-history.component.html',
  styleUrls: ['./customer-purchase-history.component.scss']
})
export class CustomerPurchaseHistoryComponent implements OnInit {
  
  _globals = GlobalService
  page = 0
  rows = 10
  totalRecords = 0
  selectedRow:any;
  showDetail:boolean = false;
  dataList: any = [
    {
     customerName:'Asim Khan',
     phone:'1239874563',
     email:'asim@gmail.com',
     city:'ABC',
     productName:'Cup',
     barcode:'123456789',
     sku:'456741',
     purchaseCount:45,
     currentPrice:412,
     totalPurchase:456,
     date:'01/04/2024'


  }
]

  //order
  orderParam = ''
  orderBy = 'asc'
  @ViewChild('p', { static: false }) paginator: Paginator;
  colheader = [[
    'Customer Name', 'phone', 'Email','City', 'Product'
    , 'Purchase Count', 'Current Price', 'Total Purchase','Last Purchase Date'
  ]];

  colflds = ['customerName', 'phone', 'email', 'city',
    'product','purchaseCount','currentPrice', 'totalPurchase', 'date'
  ];

  constructor(
    public _customerService: CustomersService,
    private _spinner: NgxSpinnerService,
    public _filterService:FilterServiceService,
    public _abd:AppBaseDataService,
    private _bs: BaseService,
  ) { }

  ngOnInit(): void {
    this._globals.componentName = 'Customer Purchase History'
  
    this.getDataList()
  
    // this.getDataList()
  }

  getDataList() {
    const data = {
      "ReportName": 'CustomerPurchaseHistory',
      "Parameter": {
        "StartDate": formatDate(this._filterService.dateRange[0], 'MM/dd/yyyy', 'en-US'),
        "EndDate": formatDate(this._filterService.dateRange[1], 'MM/dd/yyyy', 'en-US'),
        'filterValue': this._filterService.searchText,
      }
    }
    const url = environment.InventoryBaseURL + environment.Report;
    // const url = this._abd.reports.filter(x => x.paraReportName == 'customer-purchase-history')[0].reportURL;
    this._abd.showSpinner();
    this._bs.httpPost(url, JSON.stringify(data)).subscribe(res => {
      this.dataList = res.data;
      this._abd.hideSpinner();
   
    })
  }

  onPageChange(e) {
    e.totalRecords = this.totalRecords
    this.rows = e.rows
    this.page = e.page
    this._abd.setOptionToLocalStorage(this._globals.componentName, 'last-page', e.page)
    // this.getDataList()
  }
  filterButtonPressed() {
    this.page = 0
    this.paginator?.changePage(0)
    this.getDataList()
  }
  customSort(e) {
    const last = this.orderParam

    switch (e.sortField) {
      case 'itemName':
        this.orderParam = 'title'
        break;
      case 'barCode':
        this.orderParam = 'Barcode'
        break;
      case 'skuCode':
        this.orderParam = 'SkuCode'
        break;
      case 'stockInhand':
        this.orderParam = 'StockInhand'
        break;
      case 'minOrderQty':
        this.orderParam = 'MinOrderQty'
        break;
      case 'maxOrderQty':
        this.orderParam = 'MaxOrderQty'
        break;
      case 'orderQuantity':
        this.orderParam = 'OrderQuantity'
        break;
      case 'poStatus':
        this.orderParam = 'PoStatus'
        break;
      default:
        this.orderParam = ''
    }

    if (this.orderParam !== '') {
      this.orderBy = this.orderBy == 'asc' ? 'desc' : 'asc'
      if (last !== this.orderParam) this.orderBy = 'asc'
      this.getDataList()
    }
  }
  onRowSelect(e) {
    console.log(e.data)
    this.showDetail = true
    // this._purchaseService.mapItemTonewEditItem(e.data)
   
  }
  callbackFuncFromFilter(event) {
    this.getDataList()
  }

  exportExcel() {
    let data =this.dataList.map((v)=>{
      return{
        ...v,product:v.productName+"\n"+v.barcode+"\n"+v.sku
      }
     })
    this._abd.exportExcel(data, this.colheader[0], this.colflds, 'Customer Purchase History Report')
  }

  exportPdf() {
 let data =this.dataList.map((v)=>{
  return{
    ...v,product:v.productName+"\n"+v.barcode+"\n"+v.sku
  }
 })
    this._abd.exportPdf(data, this.colheader, this.colflds, 'Customer Purchase History Report',false,false,false)
   
  }
}
