<!-- <div class="conn-grid-2x"> -->
    <div>
        <div>
            <div>
                <br />
                <form
                    [formGroup]="customerGroupConfigurationform"
                     autocomplete="off"
                >

                    <div class="mb-4">
                        <p-inputSwitch [formControl]="$any(customerGroupConfigurationform.controls)['enableCustomerConfiguration']" inputId="rememberme8"></p-inputSwitch>
                        <label class="control-label" for="rememberme8"><strong> Enable</strong></label>
                    </div>
                    <br/>

                    <div class="field-checkbox mx-4 px-4" *ngIf="$any(customerGroupConfigurationform.controls)['enableCustomerConfiguration'].value === true">
                        <p-checkbox
                            [formControl]="$any(customerGroupConfigurationform.controls)['customerGroupDiscount']"
                            inputId="rememberme1"
                            [binary]="true"
                            styleClass="mr-2"
                        ></p-checkbox>
                        <label for="rememberme1">Enable Customer Group Discount</label>
                    </div>
                    <br/>

                    <div class="field-checkbox mx-4 px-4" *ngIf="$any(customerGroupConfigurationform.controls)['enableCustomerConfiguration'].value === true">
                        <p-checkbox
                            [formControl]="$any(customerGroupConfigurationform.controls)['customerGroupLoyalty']"
                            inputId="rememberme2"
                            [binary]="true"
                            styleClass="mr-2"
                        ></p-checkbox>
                        <label for="rememberme2">Enable Customer Group Loyalty Program</label>
                    </div>
                    <br/>

                    <div
                        class="flex justify-content-end flex-wrap card-container green-container mt-3"
                    >
                        <div class="flex align-items-center justify-content-center">
                            <button
                                pButton
                                pRipple
                                type="submit"
                                label="Save"
                                [disabled]="!customerGroupConfigurationform.valid"
                                (click)="saveFn()"
                                ></button>
                        </div>
                        <div
                            class="flex align-items-center justify-content-center ml-2"
                            *ngIf ="singupBusinessConfig == true"
                        >
                            <button
                                pButton
                                pRipple
                                type="button"
                                label="Cancel"
                                class="p-button-outlined p-button-danger"
                                (click)="cancelButtonClick()"
                            ></button>
                        </div>
                    </div>
                </form>
            </div>
        </div>

    </div>
