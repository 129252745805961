import { formatDate } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { MenuItem } from 'primeng/api';
import { Paginator } from 'primeng/paginator';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { BaseService } from 'src/app/_services/base.service';
import { CustomersService } from 'src/app/_services/customers.service';
import { FilterServiceService } from 'src/app/_services/filter-service.service';
import { GlobalService } from 'src/app/_services/global.service';
import { environment } from 'src/environments/environment';

export class AuditTrial {
  ActivityType = [
    // {value:1, title:'All'},
    {value:2, title:'Modifications'},
    {value:3, title:'Deletion'},
  ]
  OperationType =[
    // {value:1,title:'All'},
     {value:2,title:'Product'}, {value:3,title:'Department'}, {value:4,title:'Category'},
    {value:5,title:'Menufacturer'}, {value:6,title:'Vendor'}, {value:7,title:'Inventory Count'}, {value:8,title:'Customer'},
    {value:9,title:'Discount'},{value:10,title:'Location'},{value:11,title:'User'}, {value:12,title:'Time & Attendace'}, 
    {value:13,title:'Business Configuration'}, {value:14,title:'Subscription'}, {value:15,title:'Loyalty Program (General)'},
    {value:16,title:'Reward Configuration'}, {value:17,title:'Login'}, {value:18,title:'Change Password'}, {value:19,title:'Forget Password'},  ]
}

@Component({
  selector: 'app-audit-trial',
  templateUrl: './audit-trial.component.html',
  styleUrls: ['./audit-trial.component.scss']
})
export class AuditTrialComponent implements OnInit {

  _globals = GlobalService
  page = 0
  rows = 10
  totalRecords = 0
  selectedRow:any;
  showDetail:boolean = false;
  locationIds: any[];
  activityTypeIds: any[];
  operationTypeIds: any[];
  usersIds: any[];
  dataList: any = [
          {
        userName: "Alison Bard",
        location: "All",
        date: "7/9/2024 5:43",
        activityType: "Modification",
        operation: "Product",
        oldDetails: `
        defaultPrice "A",
          price "$271",
          productName "AGS Nitro Tech",
          barcode "1029221012829",
          tax "8.25%",
          stock 
            "location1" "35",
            "location2" "32"
        ,
          discount "True",
          trackStock "True",
          allowNegative "True"`,

      modificationDetails:`
           defaultPrice "A",
          price "$325",
          productName "AGS Nitro Tech",
          barcode "1029221012829",
          tax "8.25%",
          stock 
            "location1" "35",
            "location2" "32"
          ,
          discount "True",
          trackStock "True",
          allowNegative "True"` 
     
        
      },
      {
        userName: "Justin Owens",
        location: "Dalas- Court Yard",
        date: "4/21/2024 5:43",
        activityType: "Modification",
        operation: "Product",
        oldDetails: "",
        modificationDetails: ""
      },
      {
        userName: "Jenifer Alvin",
        location: "Houston- Ball Court",
        date: "12/14/2023 2:28",
        activityType: "Modification",
        operation: "Product",
        oldDetails: "",
        modificationDetails: ""
      },
      {
        userName: "Roger Green",
        location: "Dalas- Court Yard",
        date: "4/21/2024 5:43",
        activityType: "Delete",
        operation: "Customer",
        oldDetails: `
        defaultPrice "A",
          price "$271",
          productName "AGS Nitro Tech",
          barcode "1029221012829",
          tax "8.25%",
          stock 
            "location1" "35",
            "location2" "32"
          ,
          discount "True",
          trackStock "True",
          allowNegative "True"
        `,
         
        modificationDetails: "No Data Available"
      },
      {
        userName: "Alison Bard",
        location: "All",
        date: "7/9/2024 5:43",
        activityType: "Modification",
        operation: "Customer",
        oldDetails: `
          customerName "Alan Smith",
          loyaltyPoints "121.67",
          phone "568-659-7841",
          email "alan.smith@gmail.com"`
          
        ,
        modificationDetails:`
          customerName: "Alan Smith",
          loyaltyPoints: "251.55",
          phone: "568-659-7841",
          email: "alan.sm@yahoo.com"`
      },
      {
        userName: "Justin Owens",
        location: "Dalas- Court Yard",
        date: "4/21/2024 5:43",
        activityType: "Modification",
        operation: "User",
        oldDetails:
         ` userName "Oger minst",
          email "ogr@gmail.com",
          phone "745-655-4587",
          override1 "1111",
          override2 "2323",
          "locations" "Zilon", "Camp",
          role "Admin"`,
        modificationDetails:`
          userName "Oger minst",
          email "ogr@gmail.com",
          phone "115-544-5422",
          override1 "1111",
          override2 "2323",
          locations "Zilon", "Camp",
          role "Manager"
        `
      },
      {
        userName: "Jenifer Alvin",
        location: "Houston- Ball Court",
        date: "12/14/2023 2:28",
        activityType: "Modification",
        operation: "User",
        oldDetails: "",
        modificationDetails: ""
      },
      {
        userName: "Roger Green",
        location: "Dalas- Court Yard",
        date: "4/21/2024 5:43",
        activityType: "Delete",
        operation: "User",
        oldDetails: "",
        modificationDetails: "No Data Available"
      },
      {
        userName: "Alison Bard",
        location: "All",
        date: "7/9/2024 5:43",
        activityType: "Modification",
        operation: "Subscription",
        oldDetails:`
          subscription "Plus",
          allowedLocations "2",
          allowedRegisters "5",
          loyaltyProgram "False",
          wooCommerce "True",
          websiteDomain "True",
          billingAddress "185/D, Pine Avenue, Zip12587",
          card/DebitCard": "***********2535"`,
        modificationDetails:`
          subscription "Premium",
          allowedLocations "4",
          allowedRegisters "8",
          loyaltyProgram "True",
          wooCommerce "True",
          websiteDomain "True",
          billingAddress "185/D, Pine Avenue, Zip12587",
          Card/DebitCard: "***********2535"
         ` 
 
      },
      {
        userName: "Justin Owens",
        location: "Dalas- Court Yard",
        date: "4/21/2024 5:43",
        activityType: "Modification",
        operation: "Subscription",
        oldDetails: "",
        modificationDetails: ""
      },
      {
        userName: "Jenifer Alvin",
        location: "Houston- Ball Court",
        date: "12/14/2023 2:28",
        activityType: "Modification",
        operation: "Subscription",
        oldDetails: "",
        modificationDetails: ""
      },
      {
        userName: "Roger Green",
        location: "Dalas- Court Yard",
        date: "4/21/2024 5:43",
        activityType: "New Creation",
        operation: "Customer",
        oldDetails: "No Data Available",
        modificationDetails: `
          customerName "Alan Smith",
          loyaltyPoints "121.67",
          phone "568-659-7841",
          email "alan.smith@gmail.com"`
      },
      {
        userName: "Alison Bard",
        location: "All",
        date: "7/9/2024 5:43",
        activityType: "New Creation",
        operation: "User",
        oldDetails: "No Data Available",
        modificationDetails: `
          userName "Oger minst",
          email "ogr@gmail.com",
          phone "745-655-4587",
          override1 "1111",
          override2 "2323",
          Locations "Zilon", "Camp",
          Role "Admin"`
         
        
      },
      {
        userName: "Justin Owens",
        location: "Dalas- Court Yard",
        date: "4/21/2024 5:43",
        activityType: "",
        operation: "",
        oldDetails: "",
        modificationDetails: ""
      },
      {
        userName: "Jenifer Alvin",
        location: "Houston- Ball Court",
        date: "12/14/2023 2:28",
        activityType: "",
        operation: "",
        oldDetails: "",
        modificationDetails: ""
      },
      {
        userName: "Roger Green",
        location: "Dalas- Court Yard",
        date: "4/21/2024 5:43",
        activityType: "",
        operation: "",
        oldDetails: "",
        modificationDetails: ""
      }
    ]
  
  //order
  orderParam = ''
  orderBy = 'asc'
  @ViewChild('p', { static: false }) paginator: Paginator;
  colheader = [[
    'User Name', 'Location', 'Date-Time','Activity Type', 'Operation'
    , 'Old Details', 'Modification Details'
  ]];

  colflds = ['userName', 'location', 'date', 'activityType',
    'operation','oldDetails','modificationDetails' ];

  constructor(
    public _customerService: CustomersService,
    private _spinner: NgxSpinnerService,
    public _filterService:FilterServiceService,
    public _abd:AppBaseDataService,
    private _bs: BaseService,
  ) { }

  ngOnInit(): void {
    this._globals.componentName = 'Audit Trial'
  
    this._filterService.selectedLocation = this._abd.locations;
    this.getDataList()
  }

  getDataList() {
   this.getLocationsList()
   this.getActivityType()
   this.getOperationType()
   this.getUsers()
    const data = {
      "ReportName": 'AuditTrial',
      "Parameter": {
        "StartDate": formatDate(this._filterService.dateRange[0], 'MM/dd/yyyy', 'en-US'),
        "EndDate": formatDate(this._filterService.dateRange[1], 'MM/dd/yyyy', 'en-US'),
        'filterValue': this._filterService.searchText,
        'LocationIds': this.locationIds,
        'ActivityTypeIds': this.activityTypeIds || [],
        'OperationTypeIds': this.operationTypeIds || [],
        'UsersIds': this.usersIds || [],
        
      }
    }
    const url = environment.InventoryBaseURL + environment.Report;
    this._abd.showSpinner();
    this._bs.httpPost(url, JSON.stringify(data)).subscribe(res => {
      this.dataList = res.data;
      this._abd.hideSpinner();
   
    })
  }

  onPageChange(e) {
    e.totalRecords = this.totalRecords
    this.rows = e.rows
    this.page = e.page
    this._abd.setOptionToLocalStorage(this._globals.componentName, 'last-page', e.page)
    // this.getDataList()
  }
  filterButtonPressed() {
    this.page = 0
    this.paginator?.changePage(0)
    this.getDataList()
  }
  customSort(e) {
    const last = this.orderParam

    switch (e.sortField) {
      case 'itemName':
        this.orderParam = 'title'
        break;
      case 'barCode':
        this.orderParam = 'Barcode'
        break;
      case 'skuCode':
        this.orderParam = 'SkuCode'
        break;
      case 'stockInhand':
        this.orderParam = 'StockInhand'
        break;
      case 'minOrderQty':
        this.orderParam = 'MinOrderQty'
        break;
      case 'maxOrderQty':
        this.orderParam = 'MaxOrderQty'
        break;
      case 'orderQuantity':
        this.orderParam = 'OrderQuantity'
        break;
      case 'poStatus':
        this.orderParam = 'PoStatus'
        break;
      default:
        this.orderParam = ''
    }

    if (this.orderParam !== '') {
      this.orderBy = this.orderBy == 'asc' ? 'desc' : 'asc'
      if (last !== this.orderParam) this.orderBy = 'asc'
      this.getDataList()
    }
  }
  onRowSelect(e) {}
  callbackFuncFromFilter(event) {
    this.getDataList()
  }
  getLocationsList() {
    let self = this;
    this.locationIds = [];
    let selLocation = this._filterService.selectedLocation;

    selLocation = (typeof selLocation != 'undefined' && selLocation instanceof Array) ? selLocation : [selLocation];
    selLocation.map(function (value: { locationId: { toString: () => string; }; }) {
      self.locationIds.push(value.locationId.toString())
    })
  }
  getActivityType() {
    let self = this;
    this.activityTypeIds = [];
    let selA = this._filterService.selectedActivityType;
    selA = (typeof selA != 'undefined' && selA instanceof Array) ? selA : [selA];
    selA.map(function (value: { value: { toString: () => string; }; }) {
      self.activityTypeIds.push(value.value.toString())
    })
  }
  getOperationType() {
    let self = this;
    this.operationTypeIds = [];
    let selO = this._filterService.selectedOperationType;
    selO = (typeof selO != 'undefined' && selO instanceof Array) ? selO : [selO];
    selO.map(function (value: { value: { toString: () => string; }; }) {
      self.operationTypeIds.push(value.value.toString())
    })
  }
  getUsers() {
    let self = this;
    this.usersIds = [];
    let selLocation = this._filterService.selectedLocation;
    selLocation = (typeof selLocation != 'undefined' && selLocation instanceof Array) ? selLocation : [selLocation];
    selLocation.map(function (value: { locationId: { toString: () => string; }; }) {
      self.usersIds.push(value?.locationId.toString())
    })
  }
  exportExcel() {
    let data =this.dataList.map((v)=>{
      return{
        ...v,product:v.productName+"\n"+v.barcode+"\n"+v.sku
      }
     })
    this._abd.exportExcel(data, this.colheader[0], this.colflds, 'Audit Trial')
  }

  exportPdf() {
 let data =this.dataList.map((v)=>{
  return{
    ...v,product:v.productName+"\n"+v.barcode+"\n"+v.sku
  }
 })
    this._abd.exportPdf(data, this.colheader, this.colflds, 'Audit Trial',false,false,false)
   
  }
}
