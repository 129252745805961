import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomerGroupConfig } from 'src/app/_models/configuration-widgets/business-config';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { BusinessSetupService } from 'src/app/_services/business-setup.service';
import { ConnMessageService } from 'src/app/_services/conn-message.service';
import { GlobalService } from 'src/app/_services/global.service';

@Component({
  selector: 'app-customer-group-configuration',
  templateUrl: './customer-group-configuration.component.html',
  styleUrls: ['./customer-group-configuration.component.scss']
})
export class CustomerGroupConfigrationComponent implements OnInit {
  _global = GlobalService
  customerGroupConfigurationform: FormGroup;
  customerGroupData: CustomerGroupConfig;
  @Input() singupBusinessConfig: boolean = true;
    @Output() widgetSaved = new EventEmitter();
  constructor(private _fb: FormBuilder,
              public _businessSetupService: BusinessSetupService,
              private _connMessageService: ConnMessageService,
              private _abd: AppBaseDataService) { }

  ngOnInit(): void {
    this.initForm()
  }

  
  initForm() {
    this.customerGroupConfigurationform = this._fb.group({
      enableCustomerConfiguration: [''],
      customerGroupDiscount : [''],
      customerGroupLoyalty : [''],
     })
  }
  set f(values: CustomerGroupConfig) {
    if (this.customerGroupConfigurationform) {
      this.customerGroupConfigurationform.setValue(values);
    }
  }
  getCustomerGroupConfiguration(){
    try{
      this._abd.showSpinner()
      this._abd.getCustomerGroupConfiguration().subscribe(res=>{
        if(res.success){
          this.customerGroupData = res.data
         this.setValues(this.customerGroupData)
          this._abd.hideSpinner()
        }
      })
    }
    catch{
      this._abd.hideSpinner()
    }
  }
  setValues(values:CustomerGroupConfig) {
    this.f = {
      active:values.active || false,
      enableCustomerGroupDiscount:values.enableCustomerGroupDiscount || false,
      enableCustomerGroupLoyalty:values.enableCustomerGroupDiscount || false,
    }
}
  saveFn(){
    let customerGroupValue: CustomerGroupConfig = {
      active: this.customerGroupConfigurationform.get('enableCustomerConfiguration').value,
      enableCustomerGroupDiscount: this.customerGroupConfigurationform.get('customerGroupDiscount').value,
      enableCustomerGroupLoyalty: this.customerGroupConfigurationform.get('customerGroupLoyalty').value,
    };
    try{
      this._abd.showSpinner()
      this._businessSetupService.saveCustomerGroupConfiguration(customerGroupValue).subscribe(res=>{
        if(res.success){
          this._connMessageService.showToastMessage(`Customer group configuration saved successfully!`, 'success')
          // this._businessSetupService.showPrintingConfiguration = false
       
        }
      })
    }
    catch{
      this._connMessageService.showToastMessage(`Error saving customer group configurations!`, 'error')
      this._abd.hideSpinner()
    }
  }

  // cancelButtonClick(){
  //   this._businessSetupService.showPrintingConfiguration = false
  // }

  }
  