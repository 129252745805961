import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { OverlayPanel } from 'primeng/overlaypanel';
import { Paginator } from 'primeng/paginator';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { ConnMessageService } from 'src/app/_services/conn-message.service';
import { DiscountsService } from 'src/app/_services/discounts.service';
import { FilterServiceService } from 'src/app/_services/filter-service.service';
import { GlobalService } from 'src/app/_services/global.service';

@Component({
  selector: 'app-discounts',
  templateUrl: './discounts.component.html',
  styleUrls: ['./discounts.component.scss']
})
export class DiscountsComponent implements OnInit {

  _globals = GlobalService
  @ViewChild('op') op: OverlayPanel
  page = 0
  rows = 10
  first = 0
  totalRecords = 0
  dataList: any = null
  searchText = ''
  lastSearchText = ''
  masterDataList: any = null
  locationIds: string[] = [];
  isViewOnly = false

  actions: MenuItem[] = [
    { label: 'Edit', icon: 'pi pi-book' },
    { label: 'Delete', icon: 'pi pi-trash' },
  ]

  colheader = [[
    'Name', 'Codes', 'Start Date', 'End Date'
    , 'Limit Discount', 'Customer Type', 'Allowed Locations'
  ]];

  colflds = ['discountName', 'couponCodesListWithSpace', 'validityStartDate', 'validityEndDate',
    'limitDiscount', 'customerType', 'allowedLocationsListString'
  ];

  @ViewChild('p', { static: false }) paginator: Paginator;

  constructor(
    public _discountsService: DiscountsService,
    private _spinner: NgxSpinnerService,
    private _connMessageService: ConnMessageService,
    private _confirmationService: ConfirmationService,
    public _abd: AppBaseDataService,
    public _filterService: FilterServiceService,
  ) { }

  ngOnInit(): void {
    this._globals.componentName = 'Discounts'
  }

  ngAfterViewInit() {
    let ti = null
    ti = setInterval(() => {
      let ppages = this._filterService.getPages(this._globals.componentName)
      if (ppages) {
        let lastpage = parseInt(ppages.page)
        if (lastpage < 0) lastpage = 0
        if (lastpage > 0) {
          this.page = lastpage
          this.first = lastpage * this.rows
        }
      }

      let filter = this._filterService.getFilters(this._globals.componentName)

      if (filter) {
        if (filter.filterValue) this._filterService.searchText = filter.filterValue
        if (filter.LocationId) {
          if (filter.LocationId == '') this._filterService.selectedLocation = null
          else this._filterService.selectedLocation = this._abd.locations.find(f => f.locationId == filter.LocationId)
        }
      } else {
        // this._filterService.selectedLocation = JSON.parse(JSON.stringify(this._abd.actLocations))
      }
      try {
        if (typeof filter.applyBy !== 'undefined') {
          const cc = this._abd.productSellTypeFilter.find(f => f.id == filter.applyBy)
        }
      } catch { }

      try {
        if (typeof filter.channel !== 'undefined') {
          const ss = this._abd.channel.find(f => f.id == filter.channel)
        }
      } catch { }

      clearInterval(ti)
      this.getDataList()
    }, 500);
  }

  getInitLocations(val) {
    this._filterService.selectedLocation = []
    for (let i = 0; i < val.length; i++) {
      const loc = this._abd.actLocations.find(f => f.locationId == val[i])
      if (loc) this._filterService.selectedLocation.push(loc)
    }
    return this._filterService.selectedLocation
  }

  getLocationsList() {
    let self = this;
    this.locationIds = [];
    let selLocation = this._filterService.selectedLocation;
    selLocation = (typeof selLocation != 'undefined' && selLocation instanceof Array) ? selLocation : [selLocation];
    selLocation.map(function (value: { locationId: { toString: () => string; }; }) {
      self.locationIds.push(value.locationId.toString())
    })


  }

  getDataList() {
    // this.getLocationsList();
    this.page = this.page < 0 ? 0 : this.page
    this.rows = this.rows < 1 ? 10 : this.rows

    if (!this._filterService.selectedProductSellTypeFilter)
      this._filterService.selectedProductSellTypeFilter = this._abd.productSellTypeFilter[0]

    let loc = ''
    if (!this._filterService.selectedLocation) loc = ''
    else loc = this._filterService.selectedLocation.locationId

    const obj = {
      "PageNo": this.page,
      "PageSize": this.rows,
      "filterValue": this._filterService.searchText,
      "LocationId": loc,
      "applyBy": this._filterService.selectedChannel?.id ? this._filterService.selectedChannel?.id : -1,
      "channel": this._filterService.selectedProductSellTypeFilter.id,
    }

    this._abd.showSpinner()
    try {
      this._discountsService.getAllDiscounts(obj).subscribe(res => {
        if (res.success) {
          this.totalRecords = res.totalRecords
          this.dataList = res.data
          this.masterDataList = this.dataList;
          this.getLocationNameString();
          this._abd.hideSpinner()
          // //console.log('Discounts--', res.data)
        }
      })

      this._filterService.updateFilters(this._globals.componentName, obj)
      this._filterService.updatePages(this._globals.componentName, { page: this.page, rows: this.rows })

    } catch { this._abd.hideSpinner() }
  }

  ngOnDestroy() {
    this._globals.componentName = ''
  }

  getLocationNameString() {
    this.dataList.map(x => {
      let dummy: any = []
      x.allowedLocationsListString = []
      x.allowedLocationsList.map(y => {
        this._abd.locations.map(x1 => {
          if (x1.id == y) {
            dummy.push(x1);
          }
          x.allowedLocationsListString = dummy.map(a => a.locationName).join(', ')
          x.couponCodesListWithSpace = x.couponCodesList.join(', ')
        })
      })
    })
  }

  refrestDataList(e: any) {
    this.getDataList();
  }

  actionButtonClick(item: any) {
    this._discountsService.activeItem = JSON.parse(JSON.stringify(item))
  }

  optionDeleteClicked() {
    this.op.hide()
    this._confirmationService.confirm({
      message: 'Deleting process causes permanent loss of information. Are you sure to proceed',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      accept: () => {
        this._abd.showSpinner()
        try {
          this._discountsService.deleteDiscount(this._discountsService.activeItem.id).subscribe(res => {
            if (res.success) {
              this.getDataList();
              this._connMessageService.showToastMessage(`Discount deleted!`, 'success')
            }
          })
        } catch {
          this._abd.hideSpinner()
        }
      },
      reject: () => { }
    });
  }

  editButtonClicked() {
    this.isViewOnly = false
    this._discountsService.showEditDiscount = true;
  }
  optionStatusChangeClicked(){
    this.op.hide()
    this._confirmationService.confirm({
      message: 'Do you wish to change Status this discount?',
      header: 'Confirmation',
      icon: 'pi pi-info-circle',
      accept: () => {
        // this._abd.showSpinner()
        try {
          this._discountsService.activeItem.isActive = !this._discountsService.activeItem.isActive;
          this._discountsService.updateDiscount(this._discountsService.activeItem.id,this._discountsService.activeItem).subscribe(res => {
            if (res.success) {
              this.getDataList();
              this._connMessageService.showToastMessage(`Customer Status Changed!`, 'success')
            }
          })
        } catch {
          this._abd.hideSpinner()
        }
      },
      reject: () => { }
    });
  }
  callbackFuncFromFilter(event) {
    this.getDataList()
  }

  filterButtonPressed() {
    this.getDataList()
  }

  onPageChange(e) {
    this.page = e.page
    this.rows = e.rows
    this._abd.setOptionToLocalStorage(this._globals.componentName, 'last-page', e.page)

    this.getDataList()
  }

  exportExcel() { }

  exportPdf() { }

  onRowSelect(e) {
    this.isViewOnly = true
    this._discountsService.activeItem = JSON.parse(JSON.stringify(e.data))
    this._discountsService.showEditDiscount = true;
  }

  onRowUnselect(e) { }

}
