import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AllowedChannels, AllowedDaysList, ApplyBy, CustomerType, Discount, DiscountValueType, IfOneTimeUse, LimitDiscount, Notify, OverrideAllOtherDiscounts, printCoupon, printCouponText, printImage, PrintTextInFooter, Validity } from 'src/app/_models/discount';
import { DiscountsService } from 'src/app/_services/discounts.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { BusinessSetupService } from 'src/app/_services/business-setup.service';
import { ConnMessageService } from 'src/app/_services/conn-message.service';
import { formatDate } from '@angular/common';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { Subject } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { CustomersService } from 'src/app/_services/customers.service';

@Component({
  selector: 'app-new-edit-discounts',
  templateUrl: './new-edit-discounts.component.html',
  styleUrls: ['./new-edit-discounts.component.scss']
})
export class NewEditDiscountsComponent implements OnInit {
  destroy$: Subject<boolean> = new Subject<boolean>()
  
  editform: FormGroup
  // Bind the dropdown values to the form from the service model
  discountValueTypeList = DiscountValueType
  limitDiscountList = LimitDiscount
  ifOneTimeUseList = IfOneTimeUse
  validityList = Validity
  customerTypeList = CustomerType
  isLoadCustomerGroup:boolean = false;
  OverrideAllOtherDiscountsList = OverrideAllOtherDiscounts
  NotifyList = Notify
  PrintCouponList = printCoupon
  // PrintCouponTextList = printCouponText
  // PrintImageList = printImage
  PrintTextInFooterList = PrintTextInFooter
  AllowedChannelsList = AllowedChannels
  ApplyByList = ApplyBy
  allowedDaysList = AllowedDaysList
  discountData: Discount;
  allowedCouponsDummy = []
  fileToUpload: any;
  imageData = "assets/150-26.jpg";
  
  @Input() isNew: boolean = true
  @Input() singupBusinessConfig: boolean = true;
  @Input() isViewOnly: boolean 
  @Output() onSubmit = new EventEmitter();
  customersList: any[]=[];
  constructor(
    private _fb: FormBuilder,
    public _discountsService: DiscountsService,
    private _spinner: NgxSpinnerService,
    private _connMessageService: ConnMessageService,
    public _businessSetupService: BusinessSetupService,
    public _abd: AppBaseDataService,
    public _customerService: CustomersService
    ) {}

  ngOnInit(): void {
    this.getCustomersList()
    this._abd.initActLocations()
    this._abd.initLocations(this._abd.actLocations)
    this.discountData = {
      discountName: this._discountsService.activeItem?.discountName,
      couponCodesList: this._discountsService.activeItem?.couponCodesList,
      discountValueType: this._discountsService.activeItem?.discountValueType,
      discountValue: this._discountsService.activeItem?.discountValue,
      maximumAllowed: this._discountsService.activeItem?.maximumAllowed,
      limitDiscount: this._discountsService.activeItem?.limitDiscount,
      ifOneTimeUse: this._discountsService.activeItem?.ifOneTimeUse,
      maxCouponUse: this._discountsService.activeItem?.maxCouponUse,
      maxCouponCodeUseLimit: this._discountsService.activeItem?.maxCouponCodeUseLimit,
      validity: this._discountsService.activeItem?.validity,
      validityStartDate: this._discountsService.activeItem?.validityStartDate,
      validityEndDate: this._discountsService.activeItem?.validityEndDate,
      allowedDaysList: this._discountsService.activeItem?.allowedDaysList,
      customerType: this._discountsService.activeItem?.customerType,
      minimumItemsInCart: this._discountsService.activeItem?.minimumItemsInCart,
      maximumItemsInCart: this._discountsService.activeItem?.maximumItemsInCart,
      minimumCartValueInDollar: this._discountsService.activeItem?.minimumCartValueInDollar,
      maximumItemsValueInDollar: this._discountsService.activeItem?.maximumItemsValueInDollar,
      overrideAllOtherDiscounts: this._discountsService.activeItem?.overrideAllOtherDiscounts,
      notify: this._discountsService.activeItem?.notify,
      printTextInFooter: this._discountsService.activeItem?.printTextInFooter,
      receiptFooterText: this._discountsService.activeItem?.receiptFooterText,
      allowedChannels: this._discountsService.activeItem?.allowedChannels,
      applyBy: this._discountsService.activeItem?.applyBy,
      allowedLocationsList: this._discountsService.activeItem?.allowedLocationsList,
      printCouponAtRegister: this._discountsService.activeItem?.printCouponAtRegister,
      // printCouponText: this._discountsService.activeItem?.printCouponText,
      printText: this._discountsService.activeItem?.printText,
      printImage: this._discountsService.activeItem?.printImage,
      // image: this._discountsService.activeItem?.image,
      printCouponStartDate: this._discountsService.activeItem?.printCouponStartDate,
      printCouponEndDate: this._discountsService.activeItem?.printCouponEndDate,
      customerGroup:this._discountsService.activeItem?.customerGroup
    }
    
  }
  getCustomersList() {
    this.isLoadCustomerGroup = true
    let params = new HttpParams();
    let page = 0
    let rows = 99999

    params = params.append('pageNo', page.toString());
    params = params.append('pageSize', rows.toString());

    if (this._abd.searchText.length) params = params.append('filterValue', this._abd.searchText.trim());
    this._customerService.getAllCustomerGroups(params).subscribe(res => {
      this.customersList = res.data
      this.isLoadCustomerGroup = false
      // if new 
      // this.setCustomerGroupValue(this.customersList)
    })
  }
  setCustomerGroupValue(value:any) {
    setTimeout(() => {
      this.editform.patchValue({customerGroup:value})
    }, 500);
  }
  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  ngAfterContentInit() {
    this.initForm();
    this.setCustomValidator()
  }
  
  ngAfterViewChecked(){
    // this.setCustomValidator()
  }


  handleFileInput(event: any) {
    let file = event.target.files;
    this.fileToUpload = file.item(0);
    let fileName = this.fileToUpload.name
    const self = this
    //Show image preview
    let reader = new FileReader();
    reader.onload = (event: any) => {
      this.imageData = event.target.result;
    }
    reader.onloadend = function () {
      self._discountsService.thImages.push(reader.result)
      self._discountsService.thImageNames.push(fileName)
      // //console.log(self._businessSetupService.thImageNames)
    }
    reader.readAsDataURL(this.fileToUpload);
  }

  handleFileInputMultiple(event: any) {
    let file = event.target.files;
    if(file?.length>1 || this.fileToUpload?.length>0) return 

    const self = this
    for (let i = 0; i < file.length; i++) {
      this.fileToUpload = file.item(i);
      let fileName = this.fileToUpload.name
      //Show image preview
      let reader = new FileReader();
      reader.onload = (event: any) => {
        this.imageData = event.target.result;
      }
      reader.onloadend = function () {
        self._discountsService.thImages=[]
        self._discountsService.thImageNames=[]
        self._discountsService.thImages.push(reader.result)
        self._discountsService.thImageNames.push(fileName)
        // //console.log(self._businessSetupService.thImageNames)
      }
      reader.readAsDataURL(this.fileToUpload);
    } 
  }

  onRemoveClick(event: any, i: any) {
    this._discountsService.thImages.splice(i, 1)
    this._discountsService.thImageNames.splice(i, 1)
    this.fileToUpload=null
  }

 
  updateDropDownView(){
    // const enable=this.f.discountValueType;
    // enable?.valueChanges
    // .subscribe(value=>this.disableControls(value))
  }

  get f() {
    return this.editform.controls;
  }

  initForm() {
    let allowedDaysDataHolder = []
    let allowedLocationsDataHolder = []
    this._discountsService.activeItem?.allowedDaysList.map(y=>{
      this.allowedDaysList.map(x=>{
        if(x.id==y){
          allowedDaysDataHolder.push(x);
        }
      })
    })

    this._discountsService.activeItem?.allowedLocationsList.map(y=>{
      this._abd.locations.map(x=>{
        if(x.id==y){
          allowedLocationsDataHolder.push(x);
        }
      })
    })
    // //console.log("Allowed Days ====>>>> ",allowedDaysDataHolder)
    this.editform = this._fb.group({
      discountName: [{value: this.discountData.discountName, disabled: this.isViewOnly}, Validators.required],
      couponCodesList: [{value: this.discountData.couponCodesList, disabled: this.isViewOnly}, [Validators.required, Validators.maxLength(15)]],
      discountValueType: [{value: this.discountValueTypeList.filter(x => x.id == this.discountData.discountValueType)[0], disabled: this.isViewOnly}, Validators.required],
      customerGroup: [{value: '', disabled: this.isViewOnly}],
      discountValue: [{value: this.discountData.discountValue, disabled: this.isViewOnly},[Validators.min(0.01),Validators.required, Validators.pattern(/^([0-9]\d*)(\.\d+)?$/)]],
      maximumAllowed: [{value: this.discountData.maximumAllowed, disabled: this.isViewOnly}], // Do not add validation to this field
      limitDiscount: [{value: this.limitDiscountList.filter(x => x.id == this.discountData.limitDiscount)[0], disabled: this.isViewOnly}, Validators.required],
      ifOneTimeUse: [{value: this.ifOneTimeUseList.filter(x => x.id == this.discountData.ifOneTimeUse)[0], disabled: this.isViewOnly}], // Do not add validation to this field
      maxCouponUse: [{value: this.discountData.maxCouponUse, disabled: this.isViewOnly}], // Do not add validation to this field
      maxCouponCodeUseLimit: [{value: this.discountData.maxCouponCodeUseLimit, disabled: this.isViewOnly}], // Do not add validation to this field
      validity: [{value: this.validityList.filter(x => x.id == this.discountData.validity)[0], disabled: this.isViewOnly}, Validators.required],
      validityDate: [{value: [new Date(this.discountData.validityStartDate),new Date(this.discountData.validityEndDate)], disabled: this.isViewOnly}],  // Do not add validation to this field
      allowedDaysList: [{value: allowedDaysDataHolder, disabled: this.isViewOnly}, Validators.required],
      customerType: [{value: this.customerTypeList.filter(x => x.id == this.discountData.customerType)[0], disabled: this.isViewOnly}, Validators.required],
      minimumItemsInCart: [{value: this.discountData.minimumItemsInCart, disabled: this.isViewOnly},[Validators.required, Validators.pattern(/^([0-9]\d*)(\.\d+)?$/)]],
      maximumItemsInCart: [{value: this.discountData.maximumItemsInCart, disabled: this.isViewOnly},[Validators.required, Validators.pattern(/^([0-9]\d*)(\.\d+)?$/)]],
      minimumCartValueInDollar: [{value: this.discountData.minimumCartValueInDollar, disabled: this.isViewOnly},[Validators.required, Validators.pattern(/^([0-9]\d*)(\.\d+)?$/)]],
      maximumItemsValueInDollar: [{value: this.discountData.maximumItemsValueInDollar, disabled: this.isViewOnly},[Validators.required, Validators.pattern(/^([0-9]\d*)(\.\d+)?$/)]],
      overrideAllOtherDiscounts: [{value: this.OverrideAllOtherDiscountsList.filter(x => x.id == this.discountData.overrideAllOtherDiscounts)[0], disabled: this.isViewOnly}, Validators.required],
      notify: [{value: this.NotifyList.filter(x => x.id == this.discountData.notify)[0], disabled: this.isViewOnly}, Validators.required],
      printTextInFooter: [{value: this.PrintTextInFooterList.filter(x => x.id == this.discountData.printTextInFooter)[0], disabled: this.isViewOnly}], // Do not add validation to this field
      receiptFooterText: [{value: this.discountData.receiptFooterText, disabled: this.isViewOnly}], // Do not add validation to this field
      allowedChannels: [{value: this.AllowedChannelsList.filter(x => x.id == this.discountData.allowedChannels)[0], disabled: this.isViewOnly}, Validators.required],
      allowedLocationsList: [{value: allowedLocationsDataHolder, disabled: this.isViewOnly}, Validators.required],
      applyBy: [{value: this.ApplyByList.filter(x => x.id == this.discountData.applyBy)[0], disabled: this.isViewOnly}, Validators.required],
      printCouponAtRegister: [{value: this.PrintCouponList.filter(x => x.id == this.discountData.printCouponAtRegister )[0], disabled: this.isViewOnly}, Validators.required],
      // printImage: [{value: this.PrintImageList.filter(x => x.id == this.discountData.printImage)[0], disabled: false}, Validators.required],
      // printCouponText: [{value: this.PrintCouponTextList.filter(x => x.id == this.discountData.printCouponText)[0], disabled: false}], // Do not add validation to this field
      printText: [{value: this.discountData.printText, disabled: this.isViewOnly}], // Do not add validation to this field
      image: [{value: this.discountData.image, disabled: this.isViewOnly}], // Do not add validation to this field
      printValidityDate: [{value: [new Date(this.discountData.printCouponStartDate),new Date(this.discountData.printCouponEndDate)], disabled: this.isViewOnly}],  
    })
    // console.log(this.editform.value);
  }

  setCustomValidator(){
     
    this.f.discountValueType.valueChanges.subscribe(value=>{
      if(value.viewValue=='Percent'){
        this.f.maximumAllowed.setValidators([Validators.required]);
      }else{
        this.f.maximumAllowed.clearValidators();
      }
      this.f.maximumAllowed.updateValueAndValidity();
    })
    this.f.customerType.valueChanges.subscribe(value=>{
      if(value.id == 3){
        this.f.customerGroup.setValidators([Validators.required]);
      }else{
        this.f.customerGroup.clearValidators();
      }
      this.f.customerGroup.updateValueAndValidity();
    })

    // this.f.printCouponText.valueChanges.subscribe(value=>{
    //   if(value.viewValue=='Yes'){
    //     this.f.couponText.setValidators([Validators.required]);
    //   }else{
    //     this.f.couponText.clearValidators();
    //   }
    //   this.f.couponText.updateValueAndValidity();
    // })
    this.f.printCouponAtRegister.valueChanges.subscribe(value=>{
      if(value.viewValue=='Yes'){
        this.f.printText.setValidators([Validators.required]);
      }else{
        this.f.printText.clearValidators();
      }
      this.f.printText.updateValueAndValidity();
    })

    this.f.limitDiscount.valueChanges.subscribe(value=>{
      if(value.viewValue=='Set Max Use'){
        this.f.maxCouponUse.setValidators([Validators.required]);
        this.f.maxCouponCodeUseLimit.setValidators([Validators.required]);
        this.f.ifOneTimeUse.clearValidators();
      }else{
        this.f.maxCouponUse.clearValidators();
        this.f.maxCouponCodeUseLimit.clearValidators();
        this.f.ifOneTimeUse.setValidators([Validators.required]);
      }
      this.f.maxCouponUse.updateValueAndValidity();
      this.f.maxCouponCodeUseLimit.updateValueAndValidity();
      this.f.ifOneTimeUse.updateValueAndValidity();
    })

    this.f.validity.valueChanges.subscribe(value=>{
      if(value.viewValue == 'Between Dates'){
        this.f.validityDate.setValidators([Validators.required]);
      }else{
        this.f.validityDate.clearAsyncValidators();
      }
      this.f.validityDate.updateValueAndValidity();
    })

    this.f.notify.valueChanges.subscribe(value=>{
      if(value.viewValue == 'Yes'){
        this.f.printTextInFooter.setValidators([Validators.required]);
      }else{
        this.f.printTextInFooter.clearValidators();
      }
      this.f.printTextInFooter.updateValueAndValidity();
    })

    this.f.printTextInFooter.valueChanges.subscribe(value=>{
      if(value.viewValue == 'Yes'){
        this.f.receiptFooterText.setValidators([Validators.required]);
      }else{
        this.f.receiptFooterText.clearValidators();
      }
      this.f.receiptFooterText.updateValueAndValidity();
    })
  
    this.f.allowedChannels.valueChanges.subscribe(value=>{
      // debugger
      if(value.viewValue=='Online'){
        this.f.couponCodesList.setValidators([Validators.required, Validators.maxLength(1)]);
      }else{
        this.f.couponCodesList.clearValidators();
      }
      this.f.couponCodesList.updateValueAndValidity();
    })
  }


  saveFn(){
    this._abd.showSpinner();
    let thfiles:any[]  = []
    if (this._discountsService.thImages.length) {
      let x = 1
      for (let f of this._discountsService.thImages) {
        thfiles.push({
          content1: f,
        })
    }}

    let locationIdList = [];
    this.f.allowedLocationsList.value.map(item => locationIdList.push(item.locationId.toString()))
    const formData: Discount = {
      discountName: this.f.discountName.value,
      couponCodesList: this.f.couponCodesList.value,
      discountValueType: parseInt(this.f.discountValueType.value.id),
      discountValue: parseInt(this.f.discountValue.value),
      maximumAllowed: parseInt(this.f.maximumAllowed.value) || 0,
      limitDiscount: parseInt(this.f.limitDiscount.value.id),
      ifOneTimeUse: parseInt(this.f.ifOneTimeUse.value.id),
      maxCouponUse: parseInt(this.f.maxCouponUse.value) || 0,
      maxCouponCodeUseLimit: parseInt(this.f.maxCouponCodeUseLimit.value) || 0,
      validity: parseInt(this.f.validity.value.id),
      validityStartDate: formatDate(this.f.validityDate.value[0] || new Date(), 'MM/dd/yyyy', 'en-US'),
      validityEndDate: formatDate(this.f.validityDate.value[1] || '2050-01-01', 'MM/dd/yyyy', 'en-US'),
      allowedDaysList: (this.f.allowedDaysList.value.map(item => parseInt(item.id))),
      customerType: parseInt(this.f.customerType.value.id),
      minimumItemsInCart: parseInt(this.f.minimumItemsInCart.value),
      maximumItemsInCart: parseInt(this.f.maximumItemsInCart.value),
      minimumCartValueInDollar: parseInt(this.f.minimumCartValueInDollar.value),
      maximumItemsValueInDollar: parseInt(this.f.maximumItemsValueInDollar.value),
      overrideAllOtherDiscounts: parseInt(this.f.overrideAllOtherDiscounts.value.id),
      notify: parseInt(this.f.notify.value.id),
      printTextInFooter: parseInt(this.f.printTextInFooter.value.id),
      receiptFooterText: this.f.receiptFooterText.value,
      allowedChannels: parseInt(this.f.allowedChannels.value.id),
      allowedLocationsList: locationIdList,
      applyBy: parseInt(this.f.applyBy.value.id),
      printCouponAtRegister: parseInt(this.f.printCouponAtRegister.value.id),
      // printCouponText: parseInt(this.f.printCouponText.value),
      printText: this.f.printText.value,
      // printImage: parseInt(this.f.printImage.value.id),
      // printImage: parseInt(this.f.printImage.value.id),
      printImage: thfiles[0] ? thfiles[0].content1 : '',
      printCouponStartDate: formatDate(this.f.printValidityDate.value[0] || new Date(), 'MM/dd/yyyy', 'en-US'),
      printCouponEndDate: formatDate(this.f.printValidityDate.value[1] || '2050-01-01', 'MM/dd/yyyy', 'en-US'),
    }

    // //console.log(dummy)
    if(this.isNew){
      formData.isActive = true;
      this._discountsService.addDiscounts(formData).subscribe(res => {
        if(res.success){
          this.cancelButtonClick();
          this.onSubmit.emit(true)
          this._connMessageService.showToastMessage('Discount added successfully', 'success');
        } 
        else {
          this._connMessageService.showToastMessage(`Discount could not be added.`, 'error');
          this._abd.hideSpinner()
        }
      })
    }
    
    else{
      this._discountsService.updateDiscount(this._discountsService.activeItem?.id,formData).subscribe(res => {
        if(res.success){
          this.cancelButtonClick();
          this.onSubmit.emit(true)
          this._connMessageService.showToastMessage('Discount updated successfully', 'success');
        } 
        else {
          this._connMessageService.showToastMessage(`Discount could not be updated.`, 'error');
          this._abd.hideSpinner()
        }
      })
    }
  }

  cancelButtonClick(){
    this._discountsService.showEditDiscount = false;
    this._discountsService.showNewEditDiscount = false;
  }

  /*
    Add custom validation on each keypress to check for the following:
    	Max code =15​ => Handled from reactive form control validators.maxlength[15]
    -Min character in code =4​ => Not implemented yet.
    -max character in code =10​ => Not implemented yet.
    -Allowed Characters=A-Z,0-9,​ => Done
    convert to capital letter​ => Done
  */

  validateKey(e: any){

    let specialCharRegex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
    if(this.allowedCouponsDummy.includes(e.value) || specialCharRegex.test(e.value) || e.value.length > 10 || e.value.length < 4){
      // console.log('value already exists',e);
    }
    else{
      this.allowedCouponsDummy = this.f.couponCodesList.value
    }
    // if(this.f.allowedChannels.value.viewValue == 'Online'){
    //   console.log('online');
    // }
    this.f.couponCodesList.setValue(this.allowedCouponsDummy.map(item => item.toUpperCase()))
    // this.f.couponCodesList.updateValueAndValidity()
  }


} // end component



//   $(".downloadupaddinvoice").click(function(){
    // var filename = $('#id-input-image').val();

    // if (filename == "" || filename == null) {
    //     alert('Error');
    // }else {
    //     var file = document.getElementById('id-input-image').files[0];      
    //     var filename = document.getElementById('id-input-image').files[0].name;      
    //     var blob = new Blob([file]);
    //     var url  = URL.createObjectURL(blob);

    //     $(this).attr({ 'download': filename, 'href': url});  
    //     filename = "";
    // }

// }) 
