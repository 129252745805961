import { ProductInventoryComponent } from './partials/product-inventory/product-inventory.component';
import { ProductPricingComponent } from './partials/product-pricing/product-pricing.component';
import { GlobalService } from 'src/app/_services/global.service';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { VendorsService } from 'src/app/_services/vendors.service';
import { ManufacturersService } from 'src/app/_services/manufacturers.service';
import { CategoryService } from 'src/app/_services/category.service';
import { ProductsService } from 'src/app/_services/products.service';
import { ReportsService } from 'src/app/_services/reports.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { FileUpload } from 'primeng/fileupload';
import { ConnMessageService } from 'src/app/_services/conn-message.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ProductDetailsComponent } from './partials/product-details/product-details.component';

import { DepartmentService } from 'src/app/_services/department.service';
import { Router } from '@angular/router';
import { Platform } from '@angular/cdk/platform';
import { BarcodeLabelPrintingService } from 'src/app/_services/barcode-label-printing.service';

@Component({
  selector: 'app-create-product',
  templateUrl: './create-product.component.html',
  styleUrls: ['./create-product.component.scss']
})
export class CreateProductComponent implements OnInit {
  activeStateB: boolean[] = [true, true, true, true];
  editform!: FormGroup
  // Label Printing 
  labelForm: FormGroup
  selectedTemplate:any =null;
  dummyImageData = "https://preview.keenthemes.com/metronic8/demo2/assets/media/svg/files/blank-image.svg";

  fileToUpload: any;

  isNew = true
  tobesaved = false

  items: MenuItem[] = [
    { label: 'General', icon: 'pi pi-fw pi-home' },
    { label: 'Product Images', icon: 'pi pi-fw pi-file' },
    { label: 'Stock Management', icon: 'pi pi-fw pi-calendar' },
    { label: 'Shipping', icon: 'pi pi-fw pi-plus-circle' },
    { label: 'Additional', icon: 'pi pi-fw pi-plus-circle' },
    { label: 'Print Label', icon: 'pi pi-fw pi-print', visible:this.isNew },
  ]

  activeItem: any = { label: 'General', icon: 'pi pi-fw pi-home' }
  thFiles: any = ''

  @ViewChild('fileInput') fileInput: FileUpload;
  @ViewChild(ProductDetailsComponent) __productDetail!: ProductDetailsComponent
  @ViewChild(ProductPricingComponent) __productPricing!: ProductPricingComponent
  @ViewChild(ProductInventoryComponent) __productInventory!: ProductInventoryComponent
  _globals = GlobalService
  
  tagsForm:FormGroup;
  alreadyExistBarcodes:any;

  tempImageUrl = ""
  tempItemImage: any;
  // activeTemplateId = 1;
  templateList:any[] =[
    {templateId:1,templateName:'QR Code - with Business Name', templateURL:'assets/images/qr_code_with_business_name.png'},
    {templateId:2,templateName:'QR Code - without Business Name', templateURL:'assets/images/qr_code_without_business_name.png'},
    {templateId:3,templateName:'Barcode - with Logo', templateURL:'assets/images/barcode_with_logo.png'},
    {templateId:4,templateName:'Barcode - without Logo', templateURL:'assets/images/barcode_without_logo.png'},
    {templateId:5,templateName:'Barcode - complete Details', templateURL:'assets/images/barcode_with_compete_details.png'},
  ]

  constructor(
    public _reportService: ReportsService,
    public _catService: CategoryService,
    public _manufacturers: ManufacturersService,
    public _vendors: VendorsService,
    public _productService: ProductsService,
    public _abd: AppBaseDataService,
    private _connMessageService: ConnMessageService,
    private _spinner: NgxSpinnerService,
    private _fb: FormBuilder,
    public _departmentService: DepartmentService,
    private _router: Router,
    public _platform: Platform,
    public _barcodeLabelServices:BarcodeLabelPrintingService,
  ) { }
  selectedProductSellType = { Type: "Sell in Store only" }

  ngOnInit(): void {
    this._abd.resetAllBehaviorSubjects()
    console.log('this._platform', this._platform)
    // this.activeTemplate(1)
    // try {
    //   const abd = this._abd
    //   if (abd.departments.length < 1 || abd.categories.length < 1 || abd.manufacturers.length < 1 || abd.vendors.length < 1) {
    //     this._connMessageService.showToastMessage('No department(s)/category(s)/manufacturer(s) or vendor(s) not available!', 'error');
    //     window.history.back()
    //   }
    // } catch {
    //   this._connMessageService.showToastMessage('No department(s)/category(s)/manufacturer(s) or vendor(s) not available!', 'error');
    //   window.history.back()
    // }

    this.initForm()
    this.labelForm.valueChanges.subscribe((v)=>{
      this.labelForm.markAllAsTouched()
      this.labelForm.markAsDirty()
    })
    if (this._productService.activeItem) {
      this.items = [
        { label: 'General', icon: 'pi pi-fw pi-home' },
        { label: 'Product Images', icon: 'pi pi-fw pi-file' },
        { label: 'Stock Management', icon: 'pi pi-fw pi-calendar' },
        { label: 'Shipping', icon: 'pi pi-fw pi-plus-circle' },
        { label: 'Additional', icon: 'pi pi-fw pi-plus-circle' },
        // { label: 'Print Label', icon: 'pi pi-fw pi-print',visible:this.isNew},
      ]
      this.prepareEditItem()
    }
    else {
      // const actloc = this._abd.initActLocations()
      // if (!actloc || actloc.length < 1) {
      //   this._connMessageService.showToastMessage('No active location available.', 'error');
      //   window.history.back()
      // }

      this.restAllFields()
      this.prepareNewItem()
    }
  }


  ngOnDestroy() {
    this.restAllFields()
    this._productService.resetVariables()
  }

  ngAfterViewInit() {
    //console.log(`ngAfterViewInit - jokeViewChild is ${this.__productInventory}`)
  }

  whereToSellChanged() {
    this._productService.isSellOnline = this._abd.selectedProductSellType.id == 1 || this._abd.selectedProductSellType.id == 3
    // this._productService.isOpenPrice = this._abd.selectedProductSellType.id == 2
    if (this._productService.isSellOnline)
      this._productService.isOpenPrice = false
  }

  async prepareEditItem() {
    this.isNew = false
    const item = this._productService.activeItem
    //console.log('<< EDIT-ITEM >>', this._productService.activeItem)

    this.tempImageUrl = item.image
    this.tempImageUrl = item.image

    this.tempItemImage = item.itemPictures;

    if (this.tempImageUrl != '') {
      this._productService.featureImage = this.tempImageUrl
    }

    if (this.tempItemImage?.length > 0) {
      this.tempItemImage.forEach(element => {
        this._productService.thImages.push(element.picture)
      });
    }

    this._productService.isReturnable = item.isReturnable
    this._productService.pts.t_productName = item.itemName
    this._productService.pts.descriptionUrl = item.descriptionURL
    this._productService.isPrintAsQR = item.printAsQRCodeOnReceipt
    this._productService.productDescription = item.description

    let temp: any = ''
    temp = this._productService.productInfo.itemType.find(f => f.value === item.itemType)
    this._productService.selectedProductType = temp

    temp = this._productService.productInfo.sellAs.find(f => f.value === item.soldAs)
    this._productService.selectedSellAs = temp

    temp = this._productService.productInfo.grouping.find(f => f.value === item.grouping)
    this._productService.selectedGrouping = temp

    temp = this._productService.productInfo.discount.find(f => f.value === item.allowDiscount)
    this._productService.selectedDiscount = temp

    temp = this._productService.productInfo.ageRestriction.find(f => f.value === item.ageRestriction)
    this._productService.selectedAgeRestriction = temp

    temp = this._productService.productInfo.gender.find(f => f.value === item.gender)
    this._productService.selectedGender = temp
    //-- inventory
    this._productService.pts.sku = item.crsItemSKUs[0].skuCode
    this._productService.pts.barcode = item.crsItemSKUs[0].barcode
    // -- Secondary SKU & Barcode
   // --BST--
   this._productService.pts.altBarcode = item.crsItemSKUs[0]?.barcodes
   this._productService.pts.altSKUs = item.crsItemSKUs[0]?.skus
   this._productService.pts.altTags = item.crsItemSKUs[0]?.tags
    // --BST--
    this._productService.isTrackStock = item.trackStock
    this._productService.isAllowNegativeStock = item.allowNegativeStock
    this._productService.isSellOnline = item.sellOnline
    // item.sellOnline == true ? this._abd.selectedProductSellType = this._abd.productSellType[0] : this._abd.selectedProductSellType = this._abd.productSellType[1]
    item.sellOnline == false ? this._abd.selectedProductSellType = this._abd.productSellType[0] : this._abd.selectedProductSellType = this._abd.productSellType[1]
// Auto PO
    this._productService.pts.autoPurchaseOrder = item.crsItemSKUs[0].autoPurchaseOrder
  //  Auto PO
    //-- pricing
    this._productService.pts.priceA = item.crsItemSKUs[0].salePriceA
    this._productService.pts.priceB = item.crsItemSKUs[0].salePriceB
    this._productService.pts.priceC = item.crsItemSKUs[0].salePriceC
    this._productService.pts.minLevel = item.crsItemSKUs[0].minLevel
    this._productService.pts.maxLevel = item.crsItemSKUs[0].maxLevel
    this._productService.pts.minOrderQty = item.crsItemSKUs[0].minOrderQty
    // Auto PO
    this._productService.pts.maxOrderQty = item.crsItemSKUs[0].maxOrderQty
    // Auto PO
    this._productService.pts.reorderLevel = item.crsItemSKUs[0].reorderLevel

    this._productService.pts.costPrice = item.crsItemSKUs[0].costPrice
    this._productService.pts.discountPrice = item.crsItemSKUs[0].discountPrice

    temp = this._productService.productInfo.defaultPrice.find(f => f.value === item.crsItemSKUs[0].defaultPrice)
    this._productService.selectedDefaultPrice = temp

    this._productService.isTaxable = item.isTaxable
    this._productService.isOpenPrice = item.isOpnDept // ??

    temp = this._productService.productInfo.taxRate.find(f => f.value === item.crsItemSKUs[0].taxRateId)
    // this._productService.selectedDefaultPrice = temp

    let s = JSON.parse(JSON.stringify(temp))
    s.name = s.name + '(' + this._abd.systemConfigs.taxConfig.tax1 + '%)'
    this._productService.taxRates = []
    this._productService.taxRates.push(s)
    this._productService.selectedTaxRate = this._productService.taxRates[0]

    temp = this._productService.productInfo.sellQuantity.find(f => f.value === item.crsItemSKUs[0].sellQuantity)
    this._productService.selectedSellQuantity = temp

    temp = this._abd.uom.find(f => f.id === item.crsItemSKUs[0].uomId)
    this._abd.selectedUom = temp
    this._productService.pts.caseQty = item.crsItemSKUs[0].caseQty

    //-- product notes
    this._productService.productNotes = item.notes
    this._productService.printNotesOnReceipt = item.printOnInvoice
    this._productService.ShowNotesWhenRingingItem = item.showNotesWhen

    //-- general
    this._abd.selectedDepartment = this._abd.initActDepartments().filter(f => f.id === item.departmentId)[0]
    this._abd.selectedCategory = this._abd.initActCategories().filter(f => f.id === item.categoryId)[0]
    this._abd.selectedManufacturer = this._abd.initActManufacturers().filter(f => f.id === item.manufacturerId)[0]
    this._abd.selectedVendor = this._abd.initActVendors().filter(f => f.id === item.vendorId)[0]

    //----- shipping information
    temp = this._abd.WeightUOM.find(f => f.id == item.crsItemSKUs[0].shippingDimention.weightUOM)
    this._productService.selectedShippingWeight = temp
    this._productService.shippingWeight = item.crsItemSKUs[0].shippingDimention.weight

    temp = this._abd.DimensionUOM.find(f => f.id == item.crsItemSKUs[0].shippingDimention.dimensionUOM)
    this._productService.selectedShippingDimension = temp
    this._productService.shippingHeight = item.crsItemSKUs[0].shippingDimention.height
    this._productService.shippingWidth = item.crsItemSKUs[0].shippingDimention.width
    this._productService.shippingLength = item.crsItemSKUs[0].shippingDimention.length

    //----- shipping information
    if (item.productSellType == 'offline') this._abd.selectedProductSellType = this._abd.productSellType[0]
    if (item.productSellType == 'online') this._abd.selectedProductSellType = this._abd.productSellType[1]
    if (item.productSellType == 'all') this._abd.selectedProductSellType = this._abd.productSellType[2]

    // this._globals.sleep(1000).then(() => {
    //   // const eee = this.getBase64Image(document.getElementById("imageid"), item.image)
    // })
    setTimeout(() => {
      this.updatemetaDetails();
    }, 500);
  }

  getBase64Image(img, url) {
    var image = new Image()
    image.setAttribute('crossorigin', 'anonymous');
    image.src = url
    var canvas = document.createElement("canvas");
    canvas.width = image.width;
    canvas.height = image.height;
    var ctx = canvas.getContext("2d");
    ctx.drawImage(image, 0, 0);
    var dataURL = canvas.toDataURL("image/jpg");
    return dataURL.replace(/^data:image\/(png|jpg|jpeg|pdf|webp);base64,/, "");
  }

  restAllFields() {
    this._productService.activeItem = null

    this.tempImageUrl = ''
    this._productService.pts.t_productName = ''
    this._productService.isPrintAsQR = false
    this._productService.productDescription = ''

    this._productService.selectedLocations = []

    this._productService.selectedProductType = this._productService.productInfo.itemType[0]
    this._productService.selectedSellAs = this._productService.productInfo.sellAs[1]
    this._productService.selectedGrouping = this._productService.productInfo.grouping[0]
    this._productService.selectedDiscount = this._productService.productInfo.discount[0]
    this._productService.selectedAgeRestriction = this._productService.productInfo.ageRestriction[0]
    this._productService.selectedGender = this._productService.productInfo.gender[0]  
    //-- inventory
    this._productService.pts.sku = ''
    this._productService.pts.barcode = ''

   // --BST--
    this._productService.pts.itemSKUId = ""
    this._productService.pts.altBarcode = []
    this._productService.pts.altSKUs = []
    this._productService.pts.altTags = []

    // --BST--
    this._productService.isTrackStock = false
    this._productService.isAllowNegativeStock = false
    this._productService.isSellOnline = false

    //-- pricing
    this._productService.pts.priceA = ''
    this._productService.pts.priceB = ''
    this._productService.pts.priceC = ''

    this._productService.pts.costPrice = ''
    this._productService.pts.discountPrice = ''

    this._productService.selectedDefaultPrice = this._productService.productInfo.defaultPrice[0]

    this._productService.isTaxable = false
    this._productService.isOpenPrice = false

    this._productService.selectedDefaultPrice = this._productService.productInfo.taxRate[0]


    let s = JSON.parse(JSON.stringify(this._productService.productInfo.taxRate[0]))
    s.name = s.name + '(' + this._abd.systemConfigs.taxConfig.tax1 + '%)'
    this._productService.taxRates = []
    this._productService.taxRates.push(s)
    this._productService.selectedTaxRate = this._productService.taxRates[0]

    this._productService.selectedSellQuantity = this._productService.productInfo.sellQuantity[0]
    this._abd.selectedUom = this._abd.uom[0]
    this._productService.pts.caseQty = ''


    //-- product notes
    this._productService.productNotes = ''
    this._productService.printNotesOnReceipt = false
    this._productService.ShowNotesWhenRingingItem = false

    //-- general
    this.getInitData()

    //----- shipping information
    this._productService.selectedShippingWeight = ''
    this._productService.shippingWeight = 0

    this._productService.selectedShippingDimension = this._abd.DimensionUOM[3]
    this._productService.shippingHeight = 0
    this._productService.shippingWidth = 0
    this._productService.shippingLength = 0
  }

  prepareNewItem() {
    this._productService.isNew = true
    this._productService.isEdit = false
    this._productService.isViewOnly = false

    let sku: any = <any>this._globals.generateSKU() * 1
    this._productService.pts.sku = this._globals.generateSKU()
    this._productService.pts.barcode = this._globals.generateSKU()
    this._productService.pts.barcode = (sku + 23) + ''

    this._productService.selectedDefaultPrice = this._productService.productInfo.defaultPrice[0]
    this._abd.selectedUom = this._abd.uom.find(f => f.unitName === 'Each')

    try {
      this._productService.productNotes = ''
      this.getInitData()
      this.activeItem = this.items[0]
      // this._productService.locations = JSON.parse(JSON.stringify(this._abd.actLocations))

      for (let i = 0; i < this._productService.locations.length; i++)
        this._productService.locations[i].openingQty = 0

      this._productService.selectedLocations = []
      this._productService.selectedLocations.push(this._productService.locations[0])

      this._productService.pts.descriptionUrl = ''
      this._productService.labelPrintingImage = "";
    }
    catch {

      this._abd.loadInit().subscribe(res => {
        if (res.success) {
          this.getInitData()
          this.activeItem = this.items[0]
          this._productService.locations = JSON.parse(JSON.stringify(this._abd.locations))

          for (let i = 0; i < this._productService.locations.length; i++)
            this._productService.locations[i].openingQty = 0

          this._productService.selectedLocations = []
          this._productService.selectedLocations.push(this._productService.locations[0])
        }
      })
    }
  }
  initForm() {
    this.labelForm = this._fb.group({
      location: ['',Validators.required],
      locations: ['',Validators.required],
      noOfCopy: ['',[Validators.required,Validators.maxLength(6),Validators.pattern(/^([0-9]\d*)(\.\d+)?$/)]],
     })
     this.tagsForm = this._fb.group({
      altBarcodes:[{value:[],disabled: this._productService.isViewOnly}],
      altSKUs:[{value:[],disabled: this._productService.isViewOnly}],
      altTags:[{value:[],disabled: this._productService.isViewOnly}],
    })
    this.changeEvent()
  }
  updatemetaDetails(){
   this.tagsForm.get('altBarcodes')?.setValue(this._productService.pts?.altBarcode)
   this.tagsForm.get('altSKUs')?.setValue(this._productService.pts?.altSKUs)
   this.tagsForm.get('altTags')?.setValue(this._productService.pts?.altTags)
  }
  changeEvent() {
   this.tF.altBarcodes.valueChanges.subscribe((v)=>{
    this.findDuplicates(this.tF.altBarcodes.value,this.tF.altBarcodes)
   })
   this.tF.altSKUs.valueChanges.subscribe((v)=>{
    this.findDuplicates(this.tF.altSKUs.value,this.tF.altSKUs)
   })
   this.tF.altTags.valueChanges.subscribe((v)=>{
    this.findDuplicates(this.tF.altTags.value,this.tF.altTags)
   })

  }
  findDuplicates(arr: any[],input) {
    const seen = new Set();
    const duplicates = new Set();
  
    for (const value of arr) {
      if (seen.has(value)) {
        duplicates.add(value);
      } else {
        seen.add(value);
      }
    }
    if(Array.from(duplicates).length > 0){
        input?.setErrors({ duplicates: true });
        // this.alreadyExistBarcodes = Array.from(duplicates).join(', ')
      }
      // else{
      //   input?.setErrors(null);
      //   input?.updateValueAndValidity();;
      //   // this.alreadyExistBarcodes = ''
      // }
  }
  get f() {
    return this.labelForm.controls;
  }
  get tF() {
    return this.tagsForm.controls;
  }

//   activeTemplate(id){
//     this.activeTemplateId = id; 
// }
  getInitData() {
    this._abd.selectedCategory = this._abd.initActDepartments()[0]
    this._abd.selectedCategory = this._abd.initActCategories()[0]
    this._abd.selectedManufacturer = this._abd.initActManufacturers()[0]
    this._abd.selectedVendor = this._abd.initActVendors()[0]
  }

  onTabItemClick(tab: any) {
         this.activeItem = tab.activeItem  
  }

  accordion_b_toggle(index: number) {
    this.activeStateB[index] = !this.activeStateB[index] ? false : true
  }

  newDepartClick() {
    this._departmentService.activeItem.departmentName = ''
    this._productService.isNewDepartment = true
  }

  departmentChanged(e) {
    this._abd.actCategories = this._abd.categories.filter(
      f => f.departmentId === e.value.id
    )

    if (this._abd.actCategories.length > 0)
      this._abd.selectedCategory = this._abd.actCategories[0]
    else
      this._abd.selectedCategory = null
  }

  async saveProductClick(where2: any) {
    if (!this.validToSave()) return

    this.makeSaveObject(where2)
    // if (where2 == 'save-only')
    //   window.history.back()
    // else {
    this._productService.pts.sku = this._globals.generateSKU()
    this._productService.pts.barcode = this._globals.generateSKU()
    this._productService.pts.barcode = ((<any>(this._productService.pts.barcode) * 1) + 23) + ''

    this.__productInventory.editform.controls['sku'].setValue(this._productService.pts.sku)
    this.__productInventory.editform.controls['barcode'].setValue(this._productService.pts.barcode)
    this.__productInventory.editform.controls['maxLevel'].setValue(this._productService.pts.maxLevel)
    this.__productInventory.editform.controls['minLevel'].setValue(this._productService.pts.minLevel)
    this.__productInventory.editform.controls['reorderLevel'].setValue(this._productService.pts.reorderLevel)
    this.__productInventory.editform.controls['minOrderQty'].setValue(this._productService.pts.minOrderQty)
// Auto PO
    this.__productInventory.editform.controls['maxOrderQty'].setValue(this._productService.pts.maxOrderQty)

    this.isNew = true
    // }
    // }
  }

  makeSaveObject(where2: any) {
    try {
      const abd = this._abd
      const xLimit = 1
      if (abd.departments.length < xLimit || abd.categories.length < xLimit || abd.manufacturers.length < xLimit || abd.vendors.length < xLimit) {
        this._connMessageService.showToastMessage('No department(s)/category(s)/manufacturer(s) or vendor(s) not available!', 'error');
        return
      }
    } catch {
      this._connMessageService.showToastMessage('No department(s)/category(s)/manufacturer(s) or vendor(s) not available!', 'error');
      return
    }

    let thfiles = []
    if (this._productService.thImages.length) {
      let x = 1
      for (let f of this._productService.thImages) {
        thfiles.push({
          orderNo: x++,
          picture: f
        })
      }
    }

    let priceA = this._productService.pts.priceA ? this._productService.pts.priceA : 0
    let priceB = this._productService.pts.priceB ? this._productService.pts.priceB : 0
    let priceC = this._productService.pts.priceC ? this._productService.pts.priceC : 0
    let costPrice = this._productService.pts.costPrice ? this._productService.pts.costPrice : 0
    let caseQty = this._productService.pts.caseQty ? this._productService.pts.caseQty : 0
    let discountPrice = this._productService.pts.discountPrice ? this._productService.pts.discountPrice : 0
    let maxLevel = this._productService.pts.maxLevel ? this._productService.pts.maxLevel : 0
    let minLevel = this._productService.pts.minLevel ? this._productService.pts.minLevel : 0
    let reorderLevel = this._productService.pts.reorderLevel ? this._productService.pts.reorderLevel : 0
    let minOrderQty = this._productService.pts.minOrderQty ? this._productService.pts.minOrderQty : 0
  //  Auto PO
    let maxOrderQty = this._productService.pts.maxOrderQty ? +this._productService.pts.maxOrderQty : 0
    let autoPurchaseOrder = this._productService.pts.autoPurchaseOrder ? this._productService.pts.autoPurchaseOrder : false
// Auto PO   
    let sp = 0
    if (this._productService.selectedDefaultPrice.value === 1) sp = priceA
    else if (this._productService.selectedDefaultPrice.value === 2) sp = priceB
    else if (this._productService.selectedDefaultPrice.value === 3) sp = priceC

    let sellType = ''
    if (this._abd.selectedProductSellType.id == 1) sellType = 'online'
    else if (this._abd.selectedProductSellType.id == 2) sellType = 'offline'
    else if (this._abd.selectedProductSellType.id == 3) sellType = 'all'

    const shippingDimention = {
      weightUOM: this._productService.selectedShippingWeight.id,
      width: this._productService.shippingWidth,
      dimensionUOM: this._productService.selectedShippingDimension.id,
      height: this._productService.shippingHeight,
      weight: this._productService.shippingWeight,
      length: this._productService.shippingLength,
    }

    let os = []
    try {
      if (this._productService.selectedLocations && this._productService.selectedLocations.length > 0) {
        for (let l of this._productService.selectedLocations) {
          let qty = 0
          if (this._productService.isNew) qty = l.openingStock
          else qty = l.stockOnHand

          const model = {
            locationId: l.id,
            skuCode: this._productService.pts.sku,
            quantity: qty,
            price: sp
          }
          os.push(model)
        }
      }
    } catch { }
    // let meta_data = {
    //   itemSKUId:this._productService.pts.itemSKUId,
    //   id:this._productService.pts.itemSKUId,
    //   barcodes:this.tF.altBarcodes.value,
    //   skus:this.tF.altSKUs.value,
    //   tags:this.tF.altTags.value,
    //  }
    let skus = []
    skus.push({
      skuCode: this._productService.pts.sku,
      barcode: this._productService.pts.barcode,
        //---- 
        // --BST-- 
        barcodes:this.tF.altBarcodes.value,
        skus:this.tF.altSKUs.value,
        tags:this.tF.altTags.value,
      // --BST--
     
      costPrice: costPrice,
      salePrice: sp,
      salePriceA: priceA,
      salePriceB: priceB,
      salePriceC: priceC,
      grouping: this._productService.selectedGrouping.value,
      taxRateId: this._productService.selectedTaxRate.value,
      sellQuantity: this._productService.selectedSellQuantity.value,
      caseQty: caseQty,
      uomId: this._abd.selectedUom.id,
      openingStock: 0,
      defaultPrice: this._productService.selectedDefaultPrice.value,
      title: this._productService.pts.t_productName, //this.__productDetail?.editform.get('productName').value.trim(),
      taxable: this._productService.isTaxable,
      taxRateValue: this._productService.selectedTaxRate.value,
      defaultPriceValue: this._productService.selectedDefaultPrice.value,
      sellPriceDefaultValue: 0,
      caseQuantityValue: 0,
      unitGramValue: 0,
      discountPrice: discountPrice,
      shippingDimention: shippingDimention,
      maxLevel: maxLevel,
      minLevel: minLevel,
      reorderLevel: reorderLevel,
      minOrderQty: minOrderQty,
      // Auto PO
      maxOrderQty: maxOrderQty,
      autoPurchaseOrder: autoPurchaseOrder,
      // Auto PO
    })

    const model = {
      "itemName": this._productService.pts.t_productName, //this.__productDetail?.editform.get('productName').value.trim(),
      "notes": this._productService.productNotes.trim(),
      "descriptionURL": this._productService.pts.descriptionUrl,
      "itemType": this._productService.selectedProductType.value,
      "soldAs": this._productService.selectedSellAs.value,
      "ageRestriction": this._productService.selectedAgeRestriction.value,
      "gender": this._productService.selectedGender.value,
      "allowDiscount": this._productService.selectedDiscount.value,
      "isTaxable": this._productService.isTaxable,
      "isOpnDept": this._productService.isOpenPrice,
      "showNotesWhen": this._productService.ShowNotesWhenRingingItem,
      "printOnInvoice": this._productService.printNotesOnReceipt,
      "printAsQRCodeOnReceipt": this._productService.isPrintAsQR,
      "trackStock": this._productService.isTrackStock,
      "isActive": true,
      "createdDate": new Date(),
      "lastEdited": new Date(),
      "addedBy": this._globals.userInfo.userID,
      "departmentId": this._abd.selectedDepartment.id,
      "categoryId": this._abd.selectedCategory.id,
      "manufacturerId": this._abd.selectedManufacturer.id,
      "vendorId": this._abd.selectedVendor.id,
      "isTrackInventory": this._productService.isTrackStock,
      "allowNegativeStock": this._productService.isAllowNegativeStock,
      "description": this._productService.productDescription,
      "image": this._productService.featureImage,
      "grouping": this._productService.selectedGrouping.value,
      "crsItemSKUs": skus,
      "VariantAttributes": this._productService.variantAttributes,
      "OpeningStocks": os,
      "ItemPictures": thfiles,
      "DiscountPrice": discountPrice,
      "SellOnline": this._productService.isSellOnline,
      "productSellType": sellType,
      "isReturnable": this._productService.isReturnable,
    }

    let saved = false
    if (this.isNew) {
      try {
        this._abd.showSpinner()
        this._productService.saveProduct(JSON.stringify(model)).subscribe(res => {
          if (res.success) {
            this.saveLabel(model)
            this._connMessageService.showToastMessage('Product saved successfully.', 'success');
            saved = true
            this._abd.hideSpinner()
            if (where2 == 'save-only')
              window.history.back()
          }
        })
      } catch { this._abd.hideSpinner() }
      finally { }
    } else {
      try {
        this._abd.showSpinner()
        this._productService.updateProduct(JSON.stringify(model), this._productService.activeItem.id).subscribe(res => {
          if (res.success) {
          this.saveLabel(model)
            this._productService.selectedLocations = []
            let loc = this._abd.locations
            for (let i = 0; i < loc.length; i++) {
              try {
                delete loc[i].newQoh
              }
              catch { }
            }

            this._connMessageService.showToastMessage('Product updated successfully.', 'success');
            saved = true
            if (where2 == 'save-only') {
              window.history.back()
            }
            this._abd.hideSpinner()
          }
        })
      } catch { this._abd.hideSpinner() }
      finally {

      }
    }
  }
  //  Label Printing
  saveLabel(value?:any) {
    if( this.labelForm.valid && this.f.locations.value.length > 0 && this.f.noOfCopy.value !== ""){
      this._abd.showSpinner()
      let objList = this.f.locations.value.map((loc)=>{
    
        let locId = this.f.location.value.locationId
        let location = this._abd.locations.filter((val)=>val.locationId === locId)[0]
        let address = location.locationName+", "+ location.address+","+location.city+", "+location.state+", "+location.zip
     
    
        return {
        ProductName: value.itemName,
        Barcode: value.crsItemSKUs[0].barcode,
        Sku: value.crsItemSKUs[0].skuCode,
        Location: loc.id,
        Business: this._globals.userInfo?.businessSetup[0].businessName,
        Price: value.crsItemSKUs[0].salePrice,
        LocationName: address,
        LocationId: this.f.location.value.id,
        NoOfCopy: +this.f.noOfCopy.value,
        TemplateId: this.selectedTemplate.templateId,
        iscustom:1,
        BrandImage:this._productService.labelPrintingImage,
        Status:'0',
      }
    })
    this._barcodeLabelServices.saveBarcodeLabel(objList).subscribe(res => {
          if(res.success){
            this._abd.hideSpinner();
            this._connMessageService.showToastMessage('Product Label Printing saved successfully', 'success');
          } 
          else {
            this._connMessageService.showToastMessage(`Product Label Printing could not be saved.`, 'error');
            this._abd.hideSpinner()
          }
        },(error)=>{
          this._abd.hideSpinner()
        })
   }
  }
  
// /Label Printing
  async makeThFiles_1() {
    this.thFiles = []
    let files = []
    const self = this


    if (!this.fileInput) return
    if (!this.fileInput.files) return

    for (let file of this.fileInput.files) {
      var fileToLoad = file;
      var fileReader = new FileReader();

      fileReader.onload = function (fileLoadedEvent) {
        var srcData = fileLoadedEvent.target.result; // <--- data: base64

        var newImage = document.createElement('img');
        newImage.src = <any>srcData;

        // document.getElementById("imgTest").innerHTML = newImage.outerHTML;

        self.thFiles.push(srcData)
        //alert("Converted Base64 version is " + document.getElementById("imgTest").innerHTML);
        // //console.log("Converted Base64 version is " + document.getElementById("imgTest").innerHTML);
      }
      fileReader.readAsDataURL(fileToLoad);
    }
  }

  validToSave() {
    try {
      if (this.__productDetail)
        this._productService.validate.pd = this.__productDetail?.isFormValid()
      if (this.__productPricing)
        this._productService.validate.pp = this.__productPricing?.isFormValid()
      if (this.__productInventory)
        this._productService.validate.pi = this.__productInventory?.isFormValid()
    } catch { }
    //console.log('valid-DD', this._productService.validate.pp)

    return (
      this._productService.validate.pd &&
      this._productService.validate.pp &&
      this._productService.validate.pi
      && this.tagsForm.valid
    )
  }

  cancelClick() {
    this._abd.selectedProductSellTypeFilter = this._productService.filter.selectedProductSellTypeFilter
    this._abd.selectedLocation = this._productService.filter.selectedLocation
    this._abd.selectedDepartment = this._productService.filter.selectedDepartment
    this._abd.selectedCategory = this._productService.filter.selectedCategory
    this._abd.selectedVendor = this._productService.filter.selectedVendor
    this._abd.selectedManufacturer = this._productService.filter.selectedManufacturer

    this._productService.isNewVendor = false
    window.history.back()
  }




}
