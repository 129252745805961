
<div class="conn-card">
    <app-report-top-filter-bar
        [paraReportName]="'customer-purchase-history-report'"
        (filterCallbackFunc)="callbackFuncFromFilter($event)"
        (filterCallbackFuncUpdateFilter)="filterButtonPressed()"
        (exportExcelFn)="exportExcel()"
        (exportPdfFn)="exportPdf()"
    >
    </app-report-top-filter-bar>

    <br />
    <p-table
        #dt
        [columns]=""
        styleClass="p-datatable-striped"
        id="table-001"
        [autoLayout]="true"
        [tableStyle]="{ width: 'max-content' }"
        [value]="dataList"
        responsiveLayout="scroll"
        rowHover="true"
        selectionMode="single"
        dataKey="id"
    >
        <ng-template pTemplate="header">
            <tr>
                <th>Customer Name</th>
                <th>Phone</th>
                <th>Email</th>
                <th>City</th>
                <th class="text-center">Product</th>
                <th class="text-center">Purchase Count</th>
                <th class="text-center">Current Price</th>
                <th class="text-center">Total Purchase</th>
                <th class="text-center">Last Purchase Date</th>
            </tr>
        </ng-template>

        <ng-template pTemplate="body" let-item>
            <tr [pSelectableRow]="item" class="text-center" (click)="onRowSelect(item)">
                <td>{{ item.customerName }}</td>
                <td>{{ item.phone }}</td>
                <td>{{ item.email }}</td>
                <td>{{ item.city }}</td>
                <td class="text-center" >{{ item.productName }} <br> {{item.barcode}} <br> {{item.sku}} </td>
                <td class="text-center">
                    {{ item.purchaseCount | number }}
                </td>
                <td class="text-center">{{ item.currentPrice | currency }}</td>
                <td class="text-center">{{ item.totalPurchase | currency }}</td>
                <td>{{ item.date | date : "mediumDate" }}</td>
     
                
            </tr>
        </ng-template>

        <ng-template pTemplate="emptymessage" style="text-align: center">
                <tr>
                    <td colspan="14" style="text-align: center">
                        There is no data to show.
                    </td>
                </tr>
            </ng-template>
    </p-table>

    <br />
    <p-paginator
        #p
        *ngIf="dataList?.length"
        (onPageChange)="onPageChange($event)"
        [rows]="rows"
        [totalRecords]="totalRecords"
        [rowsPerPageOptions]="[10, 25, 50, 100, 500, 1000]"
        [showCurrentPageReport]="true"
        currentPageReportTemplate="Records: {first} of {last}, Total Records: {totalRecords}"
    >
    </p-paginator>
</div>
<ng-container *ngIf="showDetail">
    <p-dialog
        header="Purchase History Detail"
        [(visible)]="showDetail"
        [style]="{ width: '80%' }"
        modal="true"
        [dismissableMask]="true"
        [draggable]="true"
    >
        <app-customer-purchase-history-detail></app-customer-purchase-history-detail>
    </p-dialog>
</ng-container>