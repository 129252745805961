import { NgModule } from '@angular/core';
import {PlatformModule} from '@angular/cdk/platform';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { LocationStrategy, HashLocationStrategy, CurrencyPipe, PercentPipe, CommonModule, DatePipe } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { StyleClassModule } from 'primeng/styleclass';
import { AccordionModule } from 'primeng/accordion';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { AvatarModule } from 'primeng/avatar';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { BadgeModule } from 'primeng/badge';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { CarouselModule } from 'primeng/carousel';
import { CascadeSelectModule } from 'primeng/cascadeselect';
import { ChartModule } from 'primeng/chart';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipModule } from 'primeng/chip';
import { ChipsModule } from 'primeng/chips';
import { CodeHighlighterModule } from 'primeng/codehighlighter';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { ColorPickerModule } from 'primeng/colorpicker';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DataViewModule } from 'primeng/dataview';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';
import { FieldsetModule } from 'primeng/fieldset';
import { FileUploadModule } from 'primeng/fileupload';
import { GalleriaModule } from 'primeng/galleria';
import { ImageModule } from 'primeng/image';
import { InplaceModule } from 'primeng/inplace';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputMaskModule } from 'primeng/inputmask';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { KnobModule } from 'primeng/knob';
import { LightboxModule } from 'primeng/lightbox';
import { ListboxModule } from 'primeng/listbox';
import { MegaMenuModule } from 'primeng/megamenu';
import { MenuModule } from 'primeng/menu';
import { MenubarModule } from 'primeng/menubar';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import {ConfirmationService, MessageService} from 'primeng/api'
import { MultiSelectModule } from 'primeng/multiselect';
import { OrderListModule } from 'primeng/orderlist';
import { OrganizationChartModule } from 'primeng/organizationchart';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PaginatorModule } from 'primeng/paginator';
import { PanelModule } from 'primeng/panel';
import { PanelMenuModule } from 'primeng/panelmenu';
import { PasswordModule } from 'primeng/password';
import { PickListModule } from 'primeng/picklist';
import { ProgressBarModule } from 'primeng/progressbar';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RatingModule } from 'primeng/rating';
import { RippleModule } from 'primeng/ripple';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { ScrollTopModule } from 'primeng/scrolltop';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SidebarModule } from 'primeng/sidebar';
import { SkeletonModule } from 'primeng/skeleton';
import { SlideMenuModule } from 'primeng/slidemenu';
import { SliderModule } from 'primeng/slider';
import { SplitButtonModule } from 'primeng/splitbutton';
import { SplitterModule } from 'primeng/splitter';
import { StepsModule } from 'primeng/steps';
import { TabMenuModule } from 'primeng/tabmenu';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { TagModule } from 'primeng/tag';
import { TerminalModule } from 'primeng/terminal';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { TimelineModule } from 'primeng/timeline';
import { ToastModule } from 'primeng/toast';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { ToolbarModule } from 'primeng/toolbar';
import { TooltipModule } from 'primeng/tooltip';
import { TreeModule } from 'primeng/tree';
import { TreeSelectModule } from 'primeng/treeselect';
import { TreeTableModule } from 'primeng/treetable';
import { VirtualScrollerModule } from 'primeng/virtualscroller';
import { BlockViewer } from './components/blockviewer/blockviewer.component';

import { AppCodeModule } from './components/app-code/app.code.component';
import { AppComponent } from './app.component';
import { AppMainComponent } from './app.main.component';
import { AppTopBarComponent } from './app.topbar.component';
import { AppFooterComponent } from './app.footer.component';
import { AppConfigComponent } from './app.config.component';
import { AppMenuComponent } from './app.menu.component';
import { AppMenuitemComponent } from './app.menuitem.component';

import { DashboardComponent } from './components/dashboard/dashboard.component';
import { FormLayoutComponent } from './components/formlayout/formlayout.component';
import { FloatLabelComponent } from './components/floatlabel/floatlabel.component';
import { InvalidStateComponent } from './components/invalidstate/invalidstate.component';
import { InputComponent } from './components/input/input.component';
import { ButtonComponent } from './components/button/button.component';
import { TableComponent } from './components/table/table.component';
import { ListComponent } from './components/list/list.component';
import { TreeComponent } from './components/tree/tree.component';
import { PanelsComponent } from './components/panels/panels.component';
import { OverlaysComponent } from './components/overlays/overlays.component';
import { MediaComponent } from './components/media/media.component';
import { MenusComponent } from './components/menus/menus.component';
import { MessagesComponent } from './components/messages/messages.component';
import { MiscComponent } from './components/misc/misc.component';
import { EmptyComponent } from './components/empty/empty.component';
import { ChartsComponent } from './components/charts/charts.component';
import { FileComponent } from './components/file/file.component';
import { DocumentationComponent } from './components/documentation/documentation.component';
import { CrudComponent } from './components/crud/crud.component';
import { TimelineComponent } from './components/timeline/timeline.component';
import { IconsComponent } from './components/icons/icons.component';
import { BlocksComponent } from './components/blocks/blocks.component';
import { PaymentComponent } from './components/menus/payment.component';
import { ConfirmationComponent } from './components/menus/confirmation.component';
import { PersonalComponent } from './components/menus/personal.component';
import { SeatComponent } from './components/menus/seat.component';
import { LandingComponent } from './components/landing/landing.component';

import { NgxSpinnerModule } from "ngx-spinner";

import { CountryService } from './service/countryservice';
import { CustomerService } from './service/customerservice';
import { EventService } from './service/eventservice';
import { IconService } from './service/iconservice';
import { NodeService } from './service/nodeservice';
import { PhotoService } from './service/photoservice';
import { ProductService } from './service/productservice';
import { MenuService } from './service/app.menu.service';
import { ConfigService } from './service/app.config.service';
import { LoginComponent } from './components/login/login.component';
import { ErrorComponent } from './components/error/error.component';
import { NotfoundComponent } from './components/notfound/notfound.component';
import { AccessComponent } from './components/access/access.component';
import { ErrorsInterceptor } from './_interceptors/errors.interceptor';
import { JwtInterceptor } from './_interceptors/jwt.interceptor';

import { SigninComponent } from './_components/signin/signin.component';
import { SubscriptionListComponent } from './_components/subscription-plan/list/list.component'
import { SubscriptionPlanBaseComponent } from './_components/subscription-plan/subscription-plan-base/subscription-plan-base.component';
import { SignupBusinessDetailComponent } from './_components/signup/signup-business-detail/signup-business-detail.component';
import { SignupYoursDetailsComponent } from './_components/signup/signup-yours-details/signup-yours-details.component';
import { HardwarePlanWidgetBaseComponent } from './_components/shared/hardware-plan-widget-base/hardware-plan-widget-base.component';
import { HardwarePlanWidgetListComponent } from './_components/shared/hardware-plan-widget-list/hardware-plan-widget-list.component';
import { CheckoutNFinishSignupComponent } from './_components/signup/checkout-n-finish-signup/checkout-n-finish-signup.component';
import { InventoryReportComponent } from './_components/inventory/inventory-report/inventory-report.component';
import { InventoryCountComponent } from './_components/inventory/inventory-count/inventory-count.component';
import { SettingsMainComponent } from './_components/settings/settings-main/settings-main.component';
import { SettingsRightsManagementComponent } from './_components/settings/settings-rights-management/settings-rights-management.component';
import { ProductsComponentList } from './_components/inventory/products/products-list.component';
import { InventoryManufacturerComponent } from './_components/inventory/inventory-manufacturer/inventory-manufacturer.component';
import { InventoryDepartmentsComponent } from './_components/inventory/inventory-departments/inventory-departments.component';
import { InventoryCategoriesComponent } from './_components/inventory/inventory-categories/inventory-categories.component';
import { InventoryVendorsComponent } from './_components/inventory/inventory-vendors/inventory-vendors.component';
import { PurchaseReportComponent } from './_components/purchasing/purchase-report/purchase-report.component';
import { ReturnReportComponent } from './_components/reports/sales/return-report/return-report.component';
import { DepartmentSalesReportComponent } from './_components/reports/sales/department-sales-report/department-sales-report.component';
import { ReportTopFilterBarComponent } from './_components/partials/report-top-filter-bar/report-top-filter-bar.component';
import { FiltersOptionsComponent } from './_components/partials/filters-options/filters-options.component';
import { SalesInvoicesComponent } from './_components/sales-invoices/sales-invoices/sales-invoices.component';
import { SalesInvoiceDetailComponent } from './_components/sales-invoices/sales-invoice-detail/sales-invoice-detail.component';
import { PartialInvoiceDetailComponent } from './_components/sales-invoices/partials/partial-invoice-detail/partial-invoice-detail.component';
import { PartialInvoiceOrderDetailComponent } from './_components/sales-invoices/partials/partial-invoice-order-detail/partial-invoice-order-detail.component';
import { InvoiceCustomerDetailsComponent } from './_components/sales-invoices/partials/invoice-customer-details/invoice-customer-details.component';
import { InvoiceDocumentsDetailsComponent } from './_components/sales-invoices/partials/invoice-documents-details/invoice-documents-details.component';
import { InvoicePaymentAddressComponent } from './_components/sales-invoices/partials/invoice-payment-address/invoice-payment-address.component';
import { InvoiceShippingAddressComponent } from './_components/sales-invoices/partials/invoice-shipping-address/invoice-shipping-address.component';
import { InvoicePaymentDetailsComponent } from './_components/sales-invoices/partials/invoice-payment-details/invoice-payment-details.component';
import { CreateProductComponent } from './_components/inventory/products/create-product/create-product.component';
import { ProductDetailsComponent } from './_components/inventory/products/create-product/partials/product-details/product-details.component';
import { ProductGeneralPropertiesComponent } from './_components/inventory/products/create-product/partials/product-general-properties/product-general-properties.component';
import { ProductInventoryComponent } from './_components/inventory/products/create-product/partials/product-inventory/product-inventory.component';
import { ProductPricingComponent } from './_components/inventory/products/create-product/partials/product-pricing/product-pricing.component';
import { ProductStockManagementComponent } from './_components/inventory/products/create-product/partials/product-stock-management/product-stock-management.component';
import { NewEditDepartmentComponent } from './_components/departments/new-edit-department/new-edit-department.component';
import { TextInputComponent } from './_forms/text-input/text-input.component';
import { NewEditCategoryComponent } from './_components/categories/new-edit-category/new-edit-category.component';
import { NewEditManufacturerComponent } from './_components/manufacturer/new-edit-manufacturer/new-edit-manufacturer.component';
import { NewEditVendorComponent } from './_components/inventory/new-edit-vendor/new-edit-vendor.component';
import { ImageUploadComponent } from './_forms/image-upload/image-upload.component';
import { HourlySalesReportComponent } from './_components/reports/sales/hourly-sales-report/hourly-sales-report.component';
import { DailySalesReportComponent } from './_components/reports/sales/daily-sales-report/daily-sales-report.component';
import { SalesTaxReportComponent } from './_components/reports/sales/sales-tax-report/sales-tax-report.component';
import { CreditCardSalesReportComponent } from './_components/reports/sales/credit-card-sales-report/credit-card-sales-report.component';
import { CreditCardSummaryReportComponent } from './_components/reports/sales/credit-card-summary-report/credit-card-summary-report.component';
import { TenderReportComponent } from './_components/reports/sales/tender-report/tender-report.component';
import { SalesTaxReportV2Component } from './_components/reports/sales/sales-tax-report-v2/sales-tax-report-v2.component';
import { ProductNotesComponent } from './_components/inventory/products/create-product/partials/product-notes/product-notes.component';
import { NumberInputComponent } from './_forms/number-input/number-input.component';
import { ImageMultiComponent } from './_components/inventory/products/create-product/partials/image-multi/image-multi.component';
import { LocationsListComponent } from './_components/settings/locations-list/locations-list.component';
import { LocationNewEditComponent } from './_components/settings/location-new-edit/location-new-edit.component';
import { CustomersComponent } from './_components/customers/customers.component';
import { ImportProductsComponent } from './_components/inventory/import/import.component';
import { CustomerListComponent } from './_components/customers/customer-list/customer-list.component';
import { UsersListComponent } from './_components/settings/users-list/users-list.component';
import { NewEditUserComponent } from './_components/settings/new-edit-user/new-edit-user.component';
import { NewEditCustomerComponent } from './_components/customers/new-edit-customer/new-edit-customer.component';

import { NgxMaskModule, IConfig } from 'ngx-mask'
import { PhoneformatPipe } from './_pipes/app.phoneformat';
import { BusinessSetupComponent } from './_components/business-setup/business-setup.component';
import { GeneralInformationComponent } from './_components/business-setup/general-information/general-information.component';
import { LocationInformationComponent } from './_components/business-setup/location-information/location-information.component';
import { UserInformationComponent } from './_components/business-setup/user-information/user-information.component';
import { ConfigurationInformationComponent } from './_components/business-setup/configuration-information/configuration-information.component';
import { ProductShippingInfoComponent } from './_components/inventory/products/create-product/partials/product-shipping-info/product-shipping-info.component';
import { DisableControlDirective } from './_directives/disable-control.directive';
import { FinalStepComponent } from './_components/business-setup/final-step/final-step.component';
import { RedirectsignupComponent } from './_components/redirectsignup/redirectsignup.component';
import { ChangePasswordComponent } from './_components/signin/change-password/change-password.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { EmailValidationComponent } from './_components/signup/email-validation/email-validation.component';

import { CategorySalesReportComponent } from './_components/reports/sales/category-sales-report/category-sales-report.component';

import { ConfigurationButtonPartialComponent } from './_components/business-setup/configuration-partials/configuration-button-partial/configuration-button-partial.component';
import { InvoiceConfigurationComponent } from './_components/business-setup/configuration-partials/invoice-configuration/invoice-configuration.component';
import { TaxSettingComponent } from './_components/business-setup/configuration-partials/tax-setting/tax-setting.component';
import { PrintingConfigurationComponent } from './_components/business-setup/configuration-partials/printing-configuration/printing-configuration.component';
import { CashdropConfigurationComponent } from './_components/business-setup/configuration-partials/cashdrop-configuration/cashdrop-configuration.component';
import { CashdrawerConfigurationComponent } from './_components/business-setup/configuration-partials/cashdrawer-configuration/cashdrawer-configuration.component';
import { SecurityConfigurationComponent } from './_components/business-setup/configuration-partials/security-configuration/security-configuration.component';
import { TenderConfigurationComponent } from './_components/business-setup/configuration-partials/tender-configuration/tender-configuration.component';
import { DailyMoneyReportComponent } from './_components/reports/Financial/daily-money-report/daily-money-report.component';
import { DiscountSalesReportComponent } from './_components/reports/sales/discount-sales-report/discount-sales-report.component';
import { BusinessComponent } from './_components/settings/business/business.component';
import { GeneralComponent } from './_components/settings/general/general.component';
import { CustomerDetailsComponent } from './_components/customers/customer-details/customer-details.component';
import { CustomerSalesHistoryComponent } from './_components/customers/customer-sales-history/customer-sales-history.component';
import { CustomerPersonalInfoComponent } from './_components/customers/customer-personal-info/customer-personal-info.component';
import { CustomerNotificationPermissionComponent } from './_components/customers/customer-notification-permission/customer-notification-permission.component';
import { CustomerPermissionsComponent } from './_components/customers/customer-permissions/customer-permissions.component';
import { CustomerInfoChangeComponent } from './_components/customers/customer-info-change/customer-info-change.component';
import { MenuLeftComponent } from './_layout/menu-left/menu-left.component';
import { MaskInputComponent } from './_forms/mask-input/mask-input.component';
import { PhoneInputComponent } from './_forms/phone-input/phone-input.component';
import { SecondaryScreenConfigurationComponent } from './_components/business-setup/configuration-partials/secondary-screen-configuration/secondary-screen-configuration.component';
import { LoyaltyProgramConfigurationComponent } from './_components/business-setup/configuration-partials/loyalty-program-configuration/loyalty-program-configuration.component';
import { BizzCustomersListComponent } from './_business-dashboard/_customers/bizz-customers-list/bizz-customers-list.component';
import { BizzCustomerDetailComponent } from './_business-dashboard/_customers/bizz-customer-detail/bizz-customer-detail.component';
import { BizzCustomerPersonalInfoComponent } from './_business-dashboard/_customers/bizz-customer-detail/partials/bizz-customer-personal-info/bizz-customer-personal-info.component';
import { BizzCustomerSubscriptionInvoiceComponent } from './_business-dashboard/_customers/bizz-customer-detail/partials/bizz-customer-subscription-invoice/bizz-customer-subscription-invoice.component';
import { PaginatorComponent } from './_forms/paginator/paginator.component';
import { BizzCustomerSubscriptionPlanDetailsComponent } from './_business-dashboard/_customers/bizz-customer-detail/partials/bizz-customer-subscription-plan-details/bizz-customer-subscription-plan-details.component';
import { BizzCustomerHardwareDetailsComponent } from './_business-dashboard/_customers/bizz-customer-detail/partials/bizz-customer-hardware-details/bizz-customer-hardware-details.component';
import { DiscountsComponent } from './_components/discounts/discounts.component';
import { NewEditDiscountsComponent } from './_components/discounts/new-edit-discounts/new-edit-discounts.component';
import { InvoicePrintPreviewComponent } from './_components/sales-invoices/partials/invoice-print-preview/invoice-print-preview.component';
import { TransferOutFormComponent } from './_components/transfers/transfer-out-form/transfer-out-form.component';
import { LoyaltyReportComponent } from './_components/reports/sales/loyalty-report/loyalty-report.component';
import { ProductReportComponent } from './_components/reports/sales/product-report/product-report.component';
import { GrossProfitReportComponent } from './_components/reports/sales/gross-profit-report/gross-profit-report.component';
import { TransferReportComponent } from './_components/reports/sales/transfer-report/transfer-report.component';
import { TransferDetailReportComponent } from './_components/reports/sales/transfer-detail-report/transfer-detail-report.component';
import { TransfersListComponent } from './_components/transfers/transfers-list/transfers-list.component';
import { TransferReceiveComponent } from './_components/transfer-receive/transfer-receive-list.component';
import { TransferReceiveFormComponent } from './_components/transfer-receive/transfer-receive-form/transfer-receive-form.component';
import { SignupLayoutComponent } from './_components/_signup/signup-layout/signup-layout.component';
import { SignupPersonalInfoComponent } from './_components/_signup/signup-personal-info/signup-personal-info.component';
import { BottomNavigatorComponent } from './_components/_signup/bottom-navigator/bottom-navigator.component';
import { Connect360TermsAndConditionsComponent } from './_components/_static-files/connect360/connect360-terms-and-conditions/connect360-terms-and-conditions.component';
import { ConnectCbdTermsAndConditionsComponent } from './_components/_static-files/connect-cbd/connect-cbd-terms-and-conditions/connect-cbd-terms-and-conditions.component';
import { Connect360ReturnAndRefundComponent } from './_components/_static-files/connect360/connect360-return-and-refund/connect360-return-and-refund.component';
import { ConnectCbdReturnAndRefundComponent } from './_components/_static-files/connect-cbd/connect-cbd-return-and-refund/connect-cbd-return-and-refund.component';
import { LeftNavigatorComponent } from './_components/_signup/left-navigator/left-navigator.component';
import { SignupBusinessInfoComponent } from './_components/_signup/signup-business-info/signup-business-info.component';
import { SignupVerificationComponent } from './_components/_signup/signup-verification/signup-verification.component';
import { SignupHardwareComponent } from './_components/_signup/signup-hardware/signup-hardware.component';
import { SignupPaymentStep1Component } from './_components/_signup/signup-payment-step1/signup-payment-step1.component';
import { SignupCheckoutComponent } from './_components/_signup/signup-checkout/signup-checkout.component';
import { SignupWebsiteAndOnlineStoreComponent } from './_components/_signup/signup-website-and-online-store/signup-website-and-online-store.component';
import { SignupWebsiteAndOnlineStoreCardComponent } from './_components/_signup/signup-website-and-online-store-card/signup-website-and-online-store-card.component';
import { SignupHardwareListComponent } from './_components/_signup/signup-hardware-list/signup-hardware-list.component';
import { SignupCustomAppComponent } from './_components/_signup/signup-custom-app/signup-custom-app.component';
import { TransferDetailReport2Component } from './_components/reports/sales/transfer-detail-report2/transfer-detail-report2.component';
import { CustomerGroupComponent } from './_components/customer-group/customer-group.component';
import { NewEditCustomerGroupComponent } from './_components/customer-group/new-edit-customer-group/new-edit-customer-group.component';
import { CustomerAppPlansComponent } from './_components/_signup/customer-app-plans/customer-app-plans.component';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import { GroupCustomersComponent } from './_components/customer-group/group-customers/group-customers.component';
import {SpeedDialModule} from 'primeng/speeddial';
import { SubscriptionPlansLayoutComponent } from './_components/_signup/subscription-plans/subscription-plans-layout/subscription-plans-layout.component';
import { SubscriptionPlansHeaderDesktopComponent } from './_components/_signup/subscription-plans/subscription-plans-header-desktop/subscription-plans-header-desktop.component';
import { SubscriptionPlanCounterComponent } from './_components/_signup/subscription-plans/subscription-plan-counter/subscription-plan-counter.component';
import { SubscriptionPlanCardDataDesktopComponent } from './_components/_signup/subscription-plans/subscription-plan-card-data-desktop/subscription-plan-card-data-desktop.component';
import { SubscriptionPlanDetailsComponent } from './_components/_signup/subscription-plans/subscription-plan-details/subscription-plan-details.component';
import { PurchaseOrderListComponent } from './_components/purchase-orders/purchase-order-list/purchase-order-list.component';
import { PurchaseOrderCreateEditComponent } from './_components/purchase-orders/purchase-order-create-edit/purchase-order-create-edit.component';
import { ZipcodeCityStateThreeColComponent } from './_forms/zipcode-city-state-three-col/zipcode-city-state-three-col.component';
import { PurchaseOrderReceiveComponent } from './_components/purchase-orders/purchase-order-receive/purchase-order-receive.component';
import { PurchaseOrderReceiveListComponent } from './_components/purchase-orders/purchase-order-receive-list/purchase-order-receive-list.component';
import { ProductViewOnlyComponent } from './_components/inventory/products/product-view-only/product-view-only.component';
import { PurchasingReportComponent } from './_components/reports/sales/purchasing-report/purchasing-report.component';
import { InventoryAdjustementSummaryReportComponent } from './_components/reports/sales/inventory-adjustement-summary-report/inventory-adjustement-summary-report.component';
import { InventoryAdjustmentComponent } from './_components/inventory-adjustment/inventory-adjustment.component';
import { InventoryAdjustmentListComponent } from './_components/inventory-adjustment/inventory-adjustment-list/inventory-adjustment-list.component';
import { DailyCashSummaryBdComponent } from './_components/reports/Financial/daily-cash-summary-bd/daily-cash-summary-bd.component';
import { CashCountReportComponent } from './_components/reports/Financial/cash-count-report/cash-count-report.component';
import { CashDrawerReportComponent } from './_components/reports/Financial/cash-drawer-report/cash-drawer-report.component';
import { CashReconciliationComponent } from './_components/reports/Financial/cash-reconciliation/cash-reconciliation.component';
import { InventoryAdjustmentDetailComponent } from './_components/reports/sales/inventory-adjustment-detail/inventory-adjustment-detail.component';
import { PoReceiveOpenComponent } from './_components/purchase-orders/po-receive-open/po-receive-open.component';
import { PurchaseOrderClosedComponent } from './_components/purchase-orders/purchase-order-closed/purchase-order-closed.component';
import { SignupLoyaltyProgramComponent } from './_components/_signup/signup-loyalty-program/signup-loyalty-program.component';
import { LoyaltyProgramGeneralComponent } from './_components/loyalty-program/loyalty-program-general/loyalty-program-general.component';
import { PointsAccumulationComponent } from './_components/loyalty-program/loyalty-program-general/points-accumulation/points-accumulation.component';
import { PointsRedemptionComponent } from './_components/loyalty-program/loyalty-program-general/points-redemption/points-redemption.component';
import { PrintingInteractionComponent } from './_components/loyalty-program/loyalty-program-general/printing-interaction/printing-interaction.component';
import { PoClosedForReceivingComponent } from './_components/purchase-orders/po-closed-for-receiving/po-closed-for-receiving.component';
import { PoDirectReceiveComponent } from './_components/purchase-orders/po-direct-receive/po-direct-receive.component';
//import { SafePipeModule } from 'safe-pipe';
import { EditorModule } from 'primeng/editor';
import { RewardConfigurationComponent } from './_components/loyalty-program/reward-configuration/reward-configuration.component';
import { NewEditRegularRewardComponent } from './_components/loyalty-program/reward-configuration/new-edit-regular-reward/new-edit-regular-reward.component';
import { RegularRewardConfigurationComponent } from './_components/loyalty-program/reward-configuration/regular-reward-configuration/regular-reward-configuration.component';
import { TieredRewardConfigurationComponent } from './_components/loyalty-program/reward-configuration/tiered-reward-configuration/tiered-reward-configuration.component';
import { LoyaltyProgramSubscribeComponent } from './_components/loyalty-program/loyalty-program-subscribe/loyalty-program-subscribe.component';
import { LoyaltyDiscountReportComponent } from './_components/reports/sales/loyalty-discount-report/loyalty-discount-report.component';
import { TieredRewardReportComponent } from './_components/reports/sales/tiered-reward-report/tiered-reward-report.component';
import { TieredRewardCustomerReportComponent } from './_components/reports/sales/tiered-reward-customer-report/tiered-reward-customer-report.component';
import { TieredRewardSummaryReportComponent } from './_components/reports/sales/tiered-reward-summary-report/tiered-reward-summary-report.component';
import { TieredRewardProductReportComponent } from './_components/reports/sales/tiered-reward-product-report/tiered-reward-product-report.component';
import { SubscriptionManagementComponent } from './_components/settings/subscription-management/subscription-management/subscription-management.component';
import { SubscriptionComponent } from './_components/settings/subscription-management/subscription/subscription.component';
import { AuthorizeNetIntegrationComponent } from './_components/settings/subscription-management/authorize-net-integration/authorize-net-integration.component';
import { BillingAddressComponent } from './_components/settings/subscription-management/billing-address/billing-address.component';
import { BillingHistoryComponent } from './_components/settings/subscription-management/billing-history/billing-history.component';
import { SubscriptionDetailOfCustomerComponent } from './_business-dashboard/_customers/bizz-customer-detail/partials/subscription-detail-of-customer/subscription-detail-of-customer.component';
import { Connect360EndUserAgreementComponent } from './_components/_static-files/connect360/connect360-end-user-agreement/connect360-end-user-agreement.component';
import { TillReportComponent } from './_components/reports/Financial/till-report/till-report.component';
import { PurchaseOrderComponent } from './_components/business-setup/configuration-partials/purchase-order/purchase-order.component';
import { BeneficiariesDetailsComponent } from './_components/next-level-form-integration/beneficiaries-details/beneficiaries-details.component';
import { BusinessAndOtherComponent } from './_components/next-level-form-integration/business-and-other/business-and-other.component';
import { OwnerDetailsComponent } from './_components/next-level-form-integration/owner-details/owner-details.component';
import { NextLevelFormIntegrationComponent } from './_components/next-level-form-integration/next-level-form-integration.component';
import { SafePipe } from './_pipes/safe.pipe';
import { CashReconciliationNewComponent } from './_components/reports/Financial/cash-reconciliation-new/cash-reconciliation-new.component';
import { HardwareDetailsHistoryComponent } from './_components/settings/subscription-management/hardware-details-history/hardware-details-history.component';
import { ChangeSubscriptionCardComponent } from './_components/settings/subscription-management/change-subscription-card/change-subscription-card.component';
import { PaymentprocessingstatusComponent } from './_components/_signup/paymentprocessingstatus/paymentprocessingstatus.component';
import { MerchantApplicationFormComponent } from './_components/_signup/merchant-application-form/merchant-application-form.component';
import { BizzCouponCodesListComponent } from './_business-dashboard/_coupon-managment/bizz-coupon-codes/bizz-coupon-codes-list/bizz-coupon-codes-list.component';
import { BizzSalesChannelListComponent } from './_business-dashboard/_coupon-managment/bizz-sales-channel/bizz-sales-channel-list/bizz-sales-channel-list.component';
import { BizzSalesChannelFormComponent } from './_business-dashboard/_coupon-managment/bizz-sales-channel/bizz-sales-channel-form/bizz-sales-channel-form.component';
import { BizzCouponCodesFormComponent } from './_business-dashboard/_coupon-managment/bizz-coupon-codes/bizz-coupon-codes-form/bizz-coupon-codes-form.component';
import { BizzCouponSummaryReportListComponent } from './_business-dashboard/_coupon-managment/bizz-reports/cupon-summary/bizz-cupon-summary-report-list.component';
import { BizzSalesChannelReportListComponent } from './_business-dashboard/_coupon-managment/bizz-reports/sales-channel-report/bizz-sales-channel-report-list.component';
import { BarcodeLabePrintingList } from './_components/inventory/barcode-label-printing/barcode-label-printing-list/barcode-label-printing-list.component';
import { BarcodeLabePrintingForm } from './_components/inventory/barcode-label-printing/new-edit-barcode-label-printing/barcode-label-printing-form.component';
import { TipSummaryReportComponent } from './_components/reports/tip-reports/tip-summary-report/tip-summary-report.component';
import { TipDetailReportComponent } from './_components/reports/tip-reports/tip-detail-report/tip-detail-report.component';
import { PoSendEmailComponent } from './_components/purchase-orders/po-send-email/po-send-email.component';
import { TimeTrackingDetailReportComponent } from './_components/reports/time-tracking-reports/time-tracking-detail-report/time-tracking-detail-report.component';
import { TimeTrackingSummaryReportComponent } from './_components/reports/time-tracking-reports/time-tracking-summary-report/time-tracking-summary-report.component';
import { TimeTrackingConfigrationComponent } from './_components/business-setup/configuration-partials/time-tracking/time-tracking-configuration.component';
import { NgxPermissionsModule } from 'ngx-permissions';
// import { CashDiscountSalesReportComponent } from './_components/reports/sales/cash-discount-sales-report/cash-discount-sales-report.component';
import { BizzCashDiscountConfigurationComponent } from './_business-dashboard/_customers/bizz-customer-detail/partials/bizz-cash-discount-configuration/bizz-cash-discount-configuration.component';
// import { AutoGeneratePurchaseOrderComponent } from './_components/purchase-orders/auto-generate-purchase-order/auto-generate-purchase-order.component';
import { TimeAttendanceComponent } from './_components/settings/user-time-attendance/time-attendance/time-attendance.component';
import { TimeAttendanceUsersListComponent } from './_components/settings/user-time-attendance/time-attendance-users-list/users-list/time-attendance-users-list.component';
import { SignupPaymentStep2Component } from './_components/_signup/signup-payment-step2/signup-payment-step2.component';
import { CustomerGroupConfigrationComponent } from './_components/business-setup/configuration-partials/customer-group-configuration/customer-group-configuration.component';
import { AutoGeneratePurchaseOrderComponent } from './_components/purchase-orders/auto-generate-purchase-order/auto-generate-purchase-order.component';
import { CustomerPurchaseHistoryComponent } from './_components/reports/customer-purchase-history/customer-purchase-history.component';
import { CustomerPurchaseHistoryDetailComponent } from './_components/reports/customer-purchase-history/Detail/customer-purchase-history-detail.component';
import { CustomerGroupReportDetailComponent } from './_components/reports/customer-group-reports/Detail/customer-group-report-detail.component';
import { CustomerGroupReportComponent } from './_components/reports/customer-group-reports/customer-group-report.component';
import { AuditTrialComponent } from './_components/reports/audit-trial/audit-trial.component';

export const options: Partial<null|IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        PlatformModule,
        FormsModule,
        ReactiveFormsModule,
        AppRoutingModule,
        HttpClientModule,
        BrowserAnimationsModule,
        AccordionModule,
        AutoCompleteModule,
        AvatarModule,
        AvatarGroupModule,
        BadgeModule,
        BreadcrumbModule,
        ButtonModule,
        CalendarModule,
        CardModule,
        CarouselModule,
        CascadeSelectModule,
        ChartModule,
        CheckboxModule,
        ChipsModule,
        ChipModule,
        CodeHighlighterModule,
        ConfirmDialogModule,
        ConfirmPopupModule,
        ColorPickerModule,
        ContextMenuModule,
        DataViewModule,
        DialogModule,
        DividerModule,
        DropdownModule,
        FieldsetModule,
        FileUploadModule,
        GalleriaModule,
        ImageModule,
        InplaceModule,
        InputNumberModule,
        InputMaskModule,
        InputSwitchModule,
        InputTextModule,
        InputTextareaModule,
        KnobModule,
        LightboxModule,
        ListboxModule,
        MegaMenuModule,
        MenuModule,
        MenubarModule,
        MessageModule,
        MessagesModule,
        MultiSelectModule,
        NgxSpinnerModule,
        OrderListModule,
        OrganizationChartModule,
        OverlayPanelModule,
        PaginatorModule,
        PanelModule,
        PanelMenuModule,
        PasswordModule,
        PickListModule,
        ProgressBarModule,
        RadioButtonModule,
        RatingModule,
        RippleModule,
        ScrollPanelModule,
        ScrollTopModule,
        SelectButtonModule,
        SidebarModule,
        SkeletonModule,
        SlideMenuModule,
        SliderModule,
        SplitButtonModule,
        SplitterModule,
        StepsModule,
        SpeedDialModule,
        TagModule,
        TableModule,
        TabMenuModule,
        TabViewModule,
        TerminalModule,
        TieredMenuModule,
        TimelineModule,
        ToastModule,
        ToggleButtonModule,
        ToolbarModule,
        TooltipModule,
        TreeModule,
        TreeSelectModule,
        TreeTableModule,
        VirtualScrollerModule,
        AppCodeModule,
        StyleClassModule,
        NgxMaskModule.forRoot(),
        EditorModule,
        ProgressSpinnerModule,
        //SafePipeModule
        NgxPermissionsModule.forRoot(),

    ],
    declarations: [
        AppComponent,
        AppMainComponent,
        AppTopBarComponent,
        AppFooterComponent,
        AppConfigComponent,
        AppMenuComponent,
        AppMenuitemComponent,
        DashboardComponent,
        FormLayoutComponent,
        FloatLabelComponent,
        InvalidStateComponent,
        InputComponent,
        ButtonComponent,
        TableComponent,
        ListComponent,
        TreeComponent,
        PanelsComponent,
        OverlaysComponent,
        MenusComponent,
        MessagesComponent,
        MessagesComponent,
        MiscComponent,
        ChartsComponent,
        EmptyComponent,
        FileComponent,
        IconsComponent,
        DocumentationComponent,
        CrudComponent,
        TimelineComponent,
        BlocksComponent,
        BlockViewer,
        MediaComponent,
        PaymentComponent,
        ConfirmationComponent,
        PersonalComponent,
        SeatComponent,
        LandingComponent,
        LoginComponent,
        ErrorComponent,
        NotfoundComponent,
        AccessComponent,
        SigninComponent,
        SubscriptionListComponent,
        SubscriptionPlanBaseComponent,
        SignupBusinessDetailComponent,
        SignupYoursDetailsComponent,
        HardwarePlanWidgetBaseComponent,
        HardwarePlanWidgetListComponent,
        CheckoutNFinishSignupComponent,
        InventoryReportComponent,
        InventoryCountComponent,
        SettingsMainComponent,
        SettingsRightsManagementComponent,
        ProductsComponentList,
        BarcodeLabePrintingList,
        BarcodeLabePrintingForm,
        InventoryManufacturerComponent,
        InventoryDepartmentsComponent,
        InventoryCategoriesComponent,
        InventoryVendorsComponent,
        PurchaseReportComponent,
        ReturnReportComponent,
        DepartmentSalesReportComponent,
        ReportTopFilterBarComponent,
        FiltersOptionsComponent,
        SalesInvoicesComponent,
        SalesInvoiceDetailComponent,
        PartialInvoiceDetailComponent,
        PartialInvoiceOrderDetailComponent,
        InvoiceCustomerDetailsComponent,
        InvoiceDocumentsDetailsComponent,
        InvoicePaymentAddressComponent,
        InvoiceShippingAddressComponent,
        InvoicePaymentDetailsComponent,
        CreateProductComponent,
        ProductDetailsComponent,
        ProductGeneralPropertiesComponent,
        ProductInventoryComponent,
        ProductPricingComponent,
        ProductStockManagementComponent,
        NewEditDepartmentComponent,
        TextInputComponent,
        NewEditCategoryComponent,
        NewEditManufacturerComponent,
        NewEditVendorComponent,
        ImageUploadComponent,
        HourlySalesReportComponent,
        DailySalesReportComponent,
        TipSummaryReportComponent,
        TipDetailReportComponent,
        TimeTrackingSummaryReportComponent,
        TimeTrackingDetailReportComponent,
        SalesTaxReportComponent,
        CreditCardSalesReportComponent,
        CreditCardSummaryReportComponent,
        TenderReportComponent,
        SalesTaxReportV2Component,
        ProductNotesComponent,
        NumberInputComponent,
        ImageMultiComponent,
        LocationsListComponent,
        LocationNewEditComponent,
        CustomersComponent,
        ImportProductsComponent,
        CustomerListComponent,
        UsersListComponent,
        NewEditUserComponent,
        NewEditCustomerComponent,
        BusinessSetupComponent,
        GeneralInformationComponent,
        LocationInformationComponent,
        UserInformationComponent,
        ConfigurationInformationComponent,
        ProductShippingInfoComponent,
        DisableControlDirective,
        FinalStepComponent,
        RedirectsignupComponent,
        ChangePasswordComponent,
        AboutUsComponent,
        EmailValidationComponent,
        CategorySalesReportComponent,
        MenuLeftComponent,
        ConfigurationButtonPartialComponent,
        InvoiceConfigurationComponent,
        TaxSettingComponent,
        PrintingConfigurationComponent,
        CashdropConfigurationComponent,
        CashdrawerConfigurationComponent,
        SecurityConfigurationComponent,
        TenderConfigurationComponent,
        DailyMoneyReportComponent,
        DiscountSalesReportComponent,
        BusinessComponent,
        GeneralComponent,
        CustomerDetailsComponent,
        AuditTrialComponent,
        CustomerSalesHistoryComponent,
        CustomerPurchaseHistoryComponent,
        CustomerPurchaseHistoryDetailComponent,
        CustomerGroupReportComponent,
        CustomerGroupReportDetailComponent,
        CustomerPersonalInfoComponent,
        CustomerNotificationPermissionComponent,
        CustomerPermissionsComponent,
        CustomerInfoChangeComponent,
        BusinessComponent,
        GeneralComponent,
        MaskInputComponent,
        PhoneInputComponent,
        SecondaryScreenConfigurationComponent,
        LoyaltyProgramConfigurationComponent,
        BizzCustomersListComponent,
        BizzCustomerDetailComponent,
        BizzCouponCodesListComponent,
        BizzCouponCodesFormComponent,
        BizzSalesChannelListComponent,
        BizzSalesChannelFormComponent,
        BizzSalesChannelReportListComponent,
        BizzCouponSummaryReportListComponent,
        BizzCustomerPersonalInfoComponent,
        BizzCustomerSubscriptionInvoiceComponent,
        PaginatorComponent,
        BizzCustomerSubscriptionPlanDetailsComponent,
        BizzCustomerHardwareDetailsComponent,
        BizzCashDiscountConfigurationComponent,
        DiscountsComponent,
        NewEditDiscountsComponent,
        InvoicePrintPreviewComponent,
        TransferOutFormComponent,
        LoyaltyReportComponent,
        ProductReportComponent,
        GrossProfitReportComponent,
        TransferReportComponent,
        TransferDetailReportComponent,
        TransfersListComponent,
        TransferReceiveComponent,
        TransferReceiveFormComponent,
        TransferDetailReport2Component,
        SignupLayoutComponent,
        SignupPersonalInfoComponent,
        BottomNavigatorComponent,
        Connect360TermsAndConditionsComponent,
        ConnectCbdTermsAndConditionsComponent,
        Connect360ReturnAndRefundComponent,
        ConnectCbdReturnAndRefundComponent,
        LeftNavigatorComponent,
        SignupBusinessInfoComponent,
        SignupVerificationComponent,
        CustomerGroupComponent,
        NewEditCustomerGroupComponent,
        SignupHardwareComponent,
        SignupPaymentStep1Component,
        SignupPaymentStep2Component,
        SignupCheckoutComponent,
        SignupWebsiteAndOnlineStoreComponent,
        SignupWebsiteAndOnlineStoreCardComponent,
        SignupHardwareListComponent,
        SignupCustomAppComponent,
        CustomerAppPlansComponent,
        GroupCustomersComponent,
        SubscriptionPlansHeaderDesktopComponent,
        SubscriptionPlanCardDataDesktopComponent,
        SubscriptionPlanDetailsComponent,
        SubscriptionPlanCounterComponent,
        SubscriptionPlansLayoutComponent,
        PurchaseOrderListComponent,
        PurchaseOrderCreateEditComponent,
        ZipcodeCityStateThreeColComponent,
        PurchaseOrderReceiveComponent,
        PurchaseOrderReceiveListComponent,
        ProductViewOnlyComponent,
        PurchasingReportComponent,
        InventoryAdjustementSummaryReportComponent,
        InventoryAdjustmentComponent,
        InventoryAdjustmentListComponent,
        DailyCashSummaryBdComponent,
        CashCountReportComponent,
        CashDrawerReportComponent,
        CashReconciliationComponent,
        InventoryAdjustmentDetailComponent,
        PoReceiveOpenComponent,
        PurchaseOrderClosedComponent,
        PoSendEmailComponent,
        SignupLoyaltyProgramComponent,
        LoyaltyProgramGeneralComponent,
        PointsAccumulationComponent,
        PointsRedemptionComponent,
        PrintingInteractionComponent,
        RewardConfigurationComponent,
        NewEditRegularRewardComponent,
        RegularRewardConfigurationComponent,
        TieredRewardConfigurationComponent,
        TimeTrackingConfigrationComponent,
        CustomerGroupConfigrationComponent,
        PoClosedForReceivingComponent,
        PoDirectReceiveComponent,
        LoyaltyProgramSubscribeComponent,
        LoyaltyDiscountReportComponent,
        TieredRewardReportComponent,
        TieredRewardCustomerReportComponent,
        TieredRewardSummaryReportComponent,
        TieredRewardProductReportComponent,
        SubscriptionManagementComponent,
        SubscriptionComponent,
        BillingHistoryComponent,
        BillingAddressComponent,
        AuthorizeNetIntegrationComponent,
        SubscriptionDetailOfCustomerComponent,
        Connect360EndUserAgreementComponent,
        TillReportComponent,
        PurchaseOrderComponent,
        NextLevelFormIntegrationComponent,
        OwnerDetailsComponent,
        BusinessAndOtherComponent,
        BeneficiariesDetailsComponent,
        SafePipe,
        CashReconciliationNewComponent,
        HardwareDetailsHistoryComponent,
        ChangeSubscriptionCardComponent,
        PaymentprocessingstatusComponent,
        MerchantApplicationFormComponent,
        TimeAttendanceComponent,
        TimeAttendanceUsersListComponent,
        // CashDiscountSalesReportComponent,
        AutoGeneratePurchaseOrderComponent,
    ],
    providers: [
        ConfirmationService,
        MessageService,
        CurrencyPipe,
        PercentPipe,
        PhoneformatPipe,
        DatePipe,
        //{ provide: HTTP_INTERCEPTORS, useClass: Connect360ErrorsInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorsInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        {provide: Window, useValue: window},
        CountryService, CustomerService, EventService, IconService, NodeService,
        PhotoService, ProductService, MenuService, ConfigService
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
