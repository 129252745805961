import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { usTimeZoneList } from '../_data/time-zones';
import { CashDrawerConfig, CashDropConfig, CashHandlingConfig, CustomerGroupConfig, PrintingConfig, SecurityConfig, TaxConfig, TenderScreenConfig, TimeTrackingConfig } from '../_models/configuration-widgets/business-config';
import { LoyaltyConfig } from '../_models/loyalty-config';
import { AppBaseDataService } from './app-base-data.service';
import { BaseService } from './base.service';
import { GlobalService } from './global.service';

export interface enableDisableWidgetView{
  partialText: string;
  partialBorder: string;
  partialIcon: string;
}
@Injectable({
  providedIn: 'root'
})
export class BusinessSetupService {
  activeTab: any = 'configuration-setup'
  // activeTab: any = 'general-info-1'
  usTimeZones = usTimeZoneList
  searchText = ''
  selectedTimeZone: any = ''
  isDisableNextButton=false

  // User dialog options
  showNewEditUser = false
  isEditLocation = false
  isNew = false

    showTaxSetting = false;
    taxSettingVisibilityChange: Subject<boolean> = new Subject<boolean>();

  showInvoiceConfiguration = false;
  showPrintingConfiguration = false;
  showSecurityConfiguration = false;
  showCashdropConfiguration = false;
  showCashdrawerConfiguration = false;
  showTenderConfiguration = false;

  featureImage: any = null
  thImages: any = []
  thImageNames: any = []

  // BehaviorSubjects to store widget icon CSS information
  partialTextOptions = {0:'', 1:'enabled-text', 2:'disabled-text'}
  partialBorderOptions = {0: '', 1: 'enabled-border', 2: 'disabled-border'}
  partialIconOptions = {0: '', 1: 'enabled-icon', 2: 'disabled-icon'}


  private taxWidgetBS = new BehaviorSubject<enableDisableWidgetView>({partialBorder: '', partialIcon: '', partialText:''});
  public taxWidgetBS$ = this.taxWidgetBS.asObservable();


  private invoiceWidgetBS = new BehaviorSubject<enableDisableWidgetView>({partialBorder: '', partialIcon: '', partialText:''});
  public invoiceWidgetBS$ = this.invoiceWidgetBS.asObservable();


  private printingWidgetBS = new BehaviorSubject<enableDisableWidgetView>({partialBorder: '', partialIcon: '', partialText:''});
  public printingWidgetBS$ = this.printingWidgetBS.asObservable();


  private cashdropWidgetBS = new BehaviorSubject<enableDisableWidgetView>({partialBorder: '', partialIcon: '', partialText:''});
  public cashdropWidgetBS$ = this.cashdropWidgetBS.asObservable();


  private cashdrawerWidgetBS = new BehaviorSubject<enableDisableWidgetView>({partialBorder: '', partialIcon: '', partialText:''});
  public cashdrawerWidgetBS$ = this.cashdrawerWidgetBS.asObservable();


  private securityWidgetBS = new BehaviorSubject<enableDisableWidgetView>({partialBorder: '', partialIcon: '', partialText:''});
  public securityWidgetBS$ = this.securityWidgetBS.asObservable();


  private tenderWidgetBS = new BehaviorSubject<enableDisableWidgetView>({partialBorder: '', partialIcon: '', partialText:''});
  public tenderWidgetBS$ = this.tenderWidgetBS.asObservable();


  _globals = GlobalService

  weightUnitList = [
    {
      id: 1,
      value: "Kg"
    },
    {
      id: 2,
      value: "Gram"
    }
  ]

  activeItem: any = {
    "UserID": '',
    "businessType": '',
    "industryType": '',
    "ContactEmail": '',
    "HasPhysicalStore": false,
    "HasMobileApp": false,
    "HasOnlineWebsite": false,
    "HasPOS": false,
    "StandardsForms": {
      "TimeZone": '',
      "MeasuringUnit": 0,
      "WeightUnit": 0,
      "Languages": [],
      "Currencies": []
    }
  }

  constructor(private _abd: AppBaseDataService,
              private _bs: BaseService) {
    try {
      this.selectedTimeZone = this.usTimeZones[0]
      this.activeItem = {
        "UserID": this._globals.userInfo.userID,
        "businessType": this._globals.userInfo.businessSetup[0].businessType,
        "industryType": this._globals.userInfo.businessSetup[0].industry,
        "ContactEmail": this._globals.userInfo.email,
        "HasPhysicalStore": this._globals.userInfo.businessSetup[0].hasPhysicalStore,
        "HasMobileApp": this._globals.userInfo.businessSetup[0].hasMobileApp,
        "HasOnlineWebsite": this._globals.userInfo.businessSetup[0].hasOnlineWebsite,
        "HasPOS": this._globals.userInfo.businessSetup[0].hasPOS,
        "StandardsForms": {
          "TimeZone": this.usTimeZones[0].value,
          "MeasuringUnit": this._globals.userInfo.businessSetup[0].standardsForms.measuringUnit,
          "WeightUnit": this._globals.userInfo.businessSetup[0].standardsForms.weightUnit,
          "Languages": this._globals.userInfo.businessSetup[0].standardsForms.languages,
          "Currencies": this._globals.userInfo.businessSetup[0].standardsForms.currencies
        },
      }


        this.taxSettingVisibilityChange.subscribe((value) => {
            this.showTaxSetting = value
        });
    }
    catch { }
  }



  initActiveItem() {
    const tz = this.usTimeZones.find(f => f.value == this._globals.userInfo.businessSetup[0].standardsForms.timeZone)
    this._globals.sleep(100).then(() => {
      this.activeItem = {
        "UserID": this._globals.userInfo.userID,
        "businessType": this._globals.userInfo.businessSetup[0].businessType,
        "industryType": this._globals.userInfo.businessSetup[0].industry,
        "ContactEmail": this._globals.userInfo.email,
        "HasPhysicalStore": this._globals.userInfo.businessSetup[0].hasPhysicalStore,
        "HasMobileApp": this._globals.userInfo.businessSetup[0].hasMobileApp,
        "HasOnlineWebsite": this._globals.userInfo.businessSetup[0].hasOnlineWebsite,
        "HasPOS": this._globals.userInfo.businessSetup[0].hasPOS,
        "StandardsForms": {
          "TimeZone": tz.value,
          "MeasuringUnit": this._globals.userInfo.businessSetup[0].standardsForms.measuringUnit,
          "WeightUnit": this._globals.userInfo.businessSetup[0].standardsForms.WeightUnit,
          "Languages": this._globals.userInfo.businessSetup[0].standardsForms.languages,
          "Currencies": this._globals.userInfo.businessSetup[0].standardsForms.currencies
        },
      }

    })
  }

  initWidget(widgetName: string) {
    //console.log(widgetName+" widget updated")
    let activeValue
    let savedValue

    this[widgetName+"WidgetBS"].next({partialBorder: '', partialIcon: '', partialText:''});


    if(widgetName=='tax'){
      activeValue = this._abd.systemConfigs.taxConfig.active || false;
      savedValue = this._abd.systemConfigs.taxConfig.isSaved || false;
      this.setWidgetStatusHelperFunction(activeValue, savedValue, widgetName)
    }
    else if(widgetName=='invoice'){
      activeValue = this._abd.systemConfigs.cashHandlingConfig.active || false;
      savedValue = this._abd.systemConfigs.cashHandlingConfig.isSaved || false;
      this.setWidgetStatusHelperFunction(activeValue, savedValue, widgetName)
    }
    else if(widgetName=='printing'){
      activeValue = this._abd.systemConfigs.printingConfig.active || false;
      savedValue = this._abd.systemConfigs.printingConfig.isSaved || false;
      this.setWidgetStatusHelperFunction(activeValue, savedValue, widgetName)
    }
    else if(widgetName=='cashdrop'){
      activeValue = this._abd.systemConfigs.cashDropConfig.active || false;
      savedValue = this._abd.systemConfigs.cashDropConfig.isSaved || false;
      this.setWidgetStatusHelperFunction(activeValue, savedValue, widgetName)
    }
    else if(widgetName=='cashdrawer'){
      activeValue = this._abd.systemConfigs.cashDrawerConfig.active || false;
      savedValue = this._abd.systemConfigs.cashDrawerConfig.isSaved || false;
      this.setWidgetStatusHelperFunction(activeValue, savedValue, widgetName)
    }
    else if(widgetName=='security'){
      activeValue = this._abd.systemConfigs.securityConfig.active || false;
      savedValue = this._abd.systemConfigs.securityConfig.isSaved || false;
      this.setWidgetStatusHelperFunction(activeValue, savedValue, widgetName)
    }
    else if(widgetName=='tender'){
      activeValue = this._abd.systemConfigs.tenderScreenConfig.active || false;
      savedValue = this._abd.systemConfigs.tenderScreenConfig.isSaved || false;
      this.setWidgetStatusHelperFunction(activeValue, savedValue, widgetName)
    }

  }

  setWidgetStatusHelperFunction(activeValue: boolean, savedValue: boolean, widgetName: string){
    if((activeValue == false && savedValue == false) || (activeValue == true && savedValue == false)) {
      this[widgetName+"WidgetBS"].next({partialBorder: this.partialBorderOptions[0]+'-'+widgetName, partialIcon: this.partialIconOptions[0]+'-'+widgetName, partialText: this.partialTextOptions[0]});

    }
    else if((activeValue == false) && (savedValue == true)) {
      this[widgetName+"WidgetBS"].next({partialBorder: this.partialBorderOptions[2]+'-'+widgetName, partialIcon: this.partialIconOptions[2]+'-'+widgetName, partialText: this.partialTextOptions[2]})

    }
    else if((activeValue == true) && (savedValue == true)) {
      this[widgetName+"WidgetBS"].next({partialBorder: this.partialBorderOptions[0]+'-'+widgetName, partialIcon: this.partialIconOptions[0]+'-'+widgetName, partialText: this.partialTextOptions[0]});

    }
  }

  getWidget(widgetName: string) {
    return this[widgetName+"WidgetBS"];
  }

  ngOnInit(): void {
    this.initActiveItem()
  }

  saveSecondaryScreenSettings(value: any){
    const url = environment.BusinessBaseURL + "SetContentData"
    return this._bs.httpPost(url, value).pipe(map(res => {
      return res
    }))
  }

  getSecondaryScreenSettings(){
    const url = environment.BusinessBaseURL + "GetContentData?locationId=0&platform=POSAPP&useType=Promotional"
    return this._bs.httpGet(url).pipe(map(res => {
      return res
    }))
  }

  saveLoyaltyConfigSettings(value: LoyaltyConfig){
    const url = environment.BusinessBaseURL + "SetBusinessLoyaltyConfiguration"
    return this._bs.httpPost(url, value).pipe(map(res => {
      return res
    }))
  }

  getLoyaltyConfigSettings(){
    const url = environment.BusinessBaseURL + "GetBusinessLoyaltyConfiguration"
    return this._bs.httpGet(url).pipe(map(res => {
      return res
    }))
  }

  saveBusinessInformation(value: any){
    let url = environment.BusinessBaseURL + "EditBusinessInfo"
    return this._bs.httpPost(url, value).pipe(map(response =>{
      return response
    }));
  }

  saveTaxSetting(value: TaxConfig) {
    let url = environment.BusinessBaseURL + "SetBTaxConfig";
    return this._bs.httpPost(url, value).pipe(map(response =>{
      return response
    }));
  }

  saveInvoiceConfiguration(value: CashHandlingConfig) {
    let url = environment.BusinessBaseURL + "SetBCashHandlingConfig";
    return this._bs.httpPost(url, value).pipe(map(response =>{
      return response
    }));
  }

  savePrintingConfiguration(value: PrintingConfig) {
    let url = environment.BusinessBaseURL + "SetBPrintingConfig";
    return this._bs.httpPost(url, value).pipe(map(response =>{
      return response
    }));
  }

  saveSecurityConfiguration(value: SecurityConfig) {
    let url = environment.BusinessBaseURL + "SetBSecurityConfig";
    return this._bs.httpPost(url, value).pipe(map(response =>{
      return response
    }));
  }

  saveCashdropConfiguration(value: CashDropConfig) {
    let url = environment.BusinessBaseURL + "SetBCashDropConfig";
    return this._bs.httpPost(url, value).pipe(map(response =>{
      return response
    }));
  }

  saveCashdrawerConfiguration(value: CashDrawerConfig) {
    let url = environment.BusinessBaseURL + "SetBCashDrawerConfig";
    return this._bs.httpPost(url, value).pipe(map(response =>{
      return response
    }));
  }

  saveAdminTenderConfiguration(queryString:any,value: TenderScreenConfig) {
    let url = environment.BusinessBaseURL + "PTenderConfig?"+queryString;
    return this._bs.httpPost(url, value).pipe(map(response =>{
      return response
    }));
  }
  saveTenderConfiguration(queryString:any,value: TenderScreenConfig) {
    let url = environment.BusinessBaseURL + "PTenderConfig?"+queryString;
    return this._bs.httpPost(url, value).pipe(map(response =>{
      return response
    }));
  }

  // OLD
  // saveTenderConfiguration(value: TenderScreenConfig) {
  //   let url = environment.BusinessBaseURL + "SetBTenderScreenConfig";
  //   return this._bs.httpPost(url, value).pipe(map(response =>{
  //     return response
  //   }));
  // }
    
  saveTimeTrackingConfiguration(value: TimeTrackingConfig) {
    let url = environment.BusinessBaseURL + "SetTimeTracking";
    return this._bs.httpPost(url, value).pipe(map(response =>{
      return response
    }));
  }
  saveCustomerGroupConfiguration(value: CustomerGroupConfig) {
    let url = environment.BusinessBaseURL + "CustomerGroup";
    return this._bs.httpPost(url, value).pipe(map(response =>{
      return response
    }));
  }
  // saveTimeTrackingConfiguration(value: TimeTrackingConfig) {
  //   let url = environment.BusinessBaseURL + "SetBusinessTimeTrackingConfig";
  //   return this._bs.httpPost(url, value).pipe(map(response =>{
  //     return response
  //   }));
  // }

}
