import { formatDate } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { MenuItem } from 'primeng/api';
import { Paginator } from 'primeng/paginator';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { BaseService } from 'src/app/_services/base.service';
import { CustomersService } from 'src/app/_services/customers.service';
import { FilterServiceService } from 'src/app/_services/filter-service.service';
import { GlobalService } from 'src/app/_services/global.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-customer-group-report',
  templateUrl: './customer-group-report.component.html',
  styleUrls: ['./customer-group-report.component.scss']
})
export class CustomerGroupReportComponent implements OnInit {
  
  _globals = GlobalService
  page = 0
  rows = 10
  totalRecords = 0
  selectedRow:any;
  showDetail:boolean = false;
  dataList: any = [
    {
     customerGroupName:'Asim Khan',
     location:'GH/45-K',
     purchaseMode:'All',
     purchaseCount:12,
     date:'01/04/2024',
     pointRedeemed:3259,
     totalDiscountReceived:100,
     totalPurchase:456,
  }
]

  //order
  orderParam = ''
  orderBy = 'asc'
  @ViewChild('p', { static: false }) paginator: Paginator;
  locationIds: string[] = [];
  colheader = [[
    'Customer Group Name', 'Location', 'Purchase Mode','Purchase Count', 'Last Purchase Date'
    , 'Points Redeemed', 'Total Discount Received', 'Total Purchase'
    ]];

  colflds = ['customerGroupName', 'location', 'purchaseMode', 'purchaseCount',
    'date','pointRedeemed','totalDiscountReceived', 'totalPurchase'
  ];

  constructor(
    public _customerService: CustomersService,
    private _spinner: NgxSpinnerService,
    public _filterService:FilterServiceService,
    public _abd:AppBaseDataService,
    private _bs: BaseService,
  ) { }

  ngOnInit(): void {
    this._globals.componentName = 'Customer Group'
    if (!this._filterService.dateRange || this._filterService.dateRange.length < 1) this._filterService.initDates()

    this._filterService.selectedLocation = this._abd.locations;
    this.getDataList()
  }

  getDataList() {

    this.getLocationsList();
    let sp = this._filterService.selectedProductSellTypeFilter
    if (!sp) sp = 'All'
    else sp = sp.Value
   
    const data = {
      "ReportName": 'CustomerGroup',
      "Parameter": {
        "StartDate": formatDate(this._filterService.dateRange[0], 'MM/dd/yyyy', 'en-US'),
        "EndDate": formatDate(this._filterService.dateRange[1], 'MM/dd/yyyy', 'en-US'),
        "LocationIds": this.locationIds,
        'filterValue': this._filterService.searchText,
        'OrderPlacement': sp,
      }
    }

    const url = environment.InventoryBaseURL + environment.Report
    this._abd.showSpinner();
    this._bs.httpPost(url, JSON.stringify(data)).subscribe(res => {
      this.dataList = res.data;
      })
      this._abd.hideSpinner();

  }

  getLocationsList() {
    let self = this;
    this.locationIds = [];
    let selLocation = this._filterService.selectedLocation;
    selLocation = (typeof selLocation != 'undefined' && selLocation instanceof Array) ? selLocation : [selLocation];
    selLocation.map(function (value: { locationId: { toString: () => string; }; }) {
      self.locationIds.push(value.locationId.toString())
    })
  }

  onPageChange(e) {
    e.totalRecords = this.totalRecords
    this.rows = e.rows
    this.page = e.page
    this._abd.setOptionToLocalStorage(this._globals.componentName, 'last-page', e.page)
    // this.getDataList()
  }
  filterButtonPressed() {
    this.page = 0
    this.paginator?.changePage(0)
    this.getDataList()
  }
  customSort(e) {
    const last = this.orderParam

    switch (e.sortField) {
      case 'itemName':
        this.orderParam = 'title'
        break;
      case 'barCode':
        this.orderParam = 'Barcode'
        break;
      case 'skuCode':
        this.orderParam = 'SkuCode'
        break;
      case 'stockInhand':
        this.orderParam = 'StockInhand'
        break;
      case 'minOrderQty':
        this.orderParam = 'MinOrderQty'
        break;
      case 'maxOrderQty':
        this.orderParam = 'MaxOrderQty'
        break;
      case 'orderQuantity':
        this.orderParam = 'OrderQuantity'
        break;
      case 'poStatus':
        this.orderParam = 'PoStatus'
        break;
      default:
        this.orderParam = ''
    }

    if (this.orderParam !== '') {
      this.orderBy = this.orderBy == 'asc' ? 'desc' : 'asc'
      if (last !== this.orderParam) this.orderBy = 'asc'
      this.getDataList()
    }
  }
  onRowSelect(e) {
    console.log(e.data)
    this.showDetail = true
    // this._purchaseService.mapItemTonewEditItem(e.data)
   
  }
  callbackFuncFromFilter(event) {
    this.getDataList()
  }

  exportExcel() {
    let data =this.dataList.map((v)=>{
      return{
        ...v,product:v.productName+"\n"+v.barcode+"\n"+v.sku
      }
     })
    this._abd.exportExcel(data, this.colheader[0], this.colflds, 'Customer Purchase History Report')
  }

  exportPdf() {
 let data =this.dataList.map((v)=>{
  return{
    ...v,product:v.productName+"\n"+v.barcode+"\n"+v.sku
  }
 })
    this._abd.exportPdf(data, this.colheader, this.colflds, 'Customer Purchase History Report',false,false,false)
   
  }
}
