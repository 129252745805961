import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { CashDrawerConfig } from 'src/app/_models/configuration-widgets/business-config';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { BusinessSetupService } from 'src/app/_services/business-setup.service';
import { ConnMessageService } from 'src/app/_services/conn-message.service';

@Component({
  selector: 'app-cashdrawer-configuration',
  templateUrl: './cashdrawer-configuration.component.html',
  styleUrls: ['./cashdrawer-configuration.component.scss']
})
export class CashdrawerConfigurationComponent implements OnInit {

  cashdrawerform: FormGroup;
  cashdrawerSettingData: CashDrawerConfig;
  @Input() singupBusinessConfig: boolean = true;
    @Output() widgetSaved = new EventEmitter();
  constructor(private _fb: FormBuilder,
              public _businessSetupService: BusinessSetupService,
              private _spinner: NgxSpinnerService,
              private _connMessageService: ConnMessageService,
              private _abd: AppBaseDataService) { }

  ngOnInit(): void {
    this.cashdrawerSettingData = {
      // useCashDrawer: this._abd.systemConfigs.cashDrawerConfig.useCashDrawer,
      openCashDrawerOnCashTransaction: this._abd.systemConfigs.cashDrawerConfig.openCashDrawerOnCashTransaction,
      openCashDrawerCashDrop: this._abd.systemConfigs.cashDrawerConfig.openCashDrawerCashDrop,
      // openCashDrawerOnPayOut: this._abd.systemConfigs.cashDrawerConfig.openCashDrawerOnPayOut,
      overShortAmount: this._abd.systemConfigs.cashDrawerConfig.overShortAmount,
      showCurrentTillAmount: this._abd.systemConfigs.cashDrawerConfig.showCurrentTillAmount,
      // enableTillManagement: this._abd.systemConfigs.cashDrawerConfig.enableTillManagement,
      reqVerificationTillManagement : this._abd.systemConfigs.cashDrawerConfig.reqVerificationTillManagement,
      forceTillManagement : this._abd.systemConfigs.cashDrawerConfig.forceTillManagement,
      active: this._abd.systemConfigs.cashDrawerConfig.active,
    }
  }

  ngAfterContentInit() {
    this.initForm()
    const enable=this.cashdrawerform.get('enableCashdrawerForm');
    enable?.valueChanges
      .subscribe(value=>this.disableControls(value))
  }

  disableControls(value){

    if(value==false){
      this.cashdrawerform.get('openDrawerOnCashTransaction')?.disable();
      this.cashdrawerform.get('openDrawerOnCashDrop')?.disable();
      // this.cashdrawerform.get('openDrawerOnPayout')?.disable();
      this.cashdrawerform.get('useCashDrawer')?.disable();
      this.cashdrawerform.get('overShortAmount')?.disable();
      this.cashdrawerform.get('showCurrentTillAmount')?.disable();
      // this.cashdrawerform.get('enableTillManagement')?.disable();
      this.cashdrawerform.get('reqVerificationTillManagement')?.disable();
      this.cashdrawerform.get('forceTillManagement')?.disable();

    }else if (value==true){
      this.cashdrawerform.get('openDrawerOnCashTransaction')?.enable();
      this.cashdrawerform.get('openDrawerOnCashDrop')?.enable();
      // this.cashdrawerform.get('openDrawerOnPayout')?.enable();
      this.cashdrawerform.get('useCashDrawer')?.enable();
      this.cashdrawerform.get('overShortAmount')?.enable();
      this.cashdrawerform.get('showCurrentTillAmount')?.enable();
        // this.cashdrawerform.get('enableTillManagement')?.enable();
      this.cashdrawerform.get('reqVerificationTillManagement')?.disable();
      this.cashdrawerform.get('forceTillManagement')?.disable();
    }
  }

  initForm() {
    this.cashdrawerform = this._fb.group({
      enableCashdrawerForm: [this.cashdrawerSettingData.active, Validators.required],
      useCashDrawer : [{value:this.cashdrawerSettingData.useCashDrawer,disabled:!this.cashdrawerSettingData.active}],
      openDrawerOnCashTransaction : [{value:this.cashdrawerSettingData.openCashDrawerOnCashTransaction,disabled:!this.cashdrawerSettingData.active}],
      openDrawerOnCashDrop : [{value:this.cashdrawerSettingData.openCashDrawerCashDrop,disabled:!this.cashdrawerSettingData.active}],
      // openDrawerOnPayout : [ {value:this.cashdrawerSettingData.openCashDrawerOnPayOut,disabled:!this.cashdrawerSettingData.active}],
      overShortAmount : [ {value:this.cashdrawerSettingData.overShortAmount,disabled:!this.cashdrawerSettingData.active}],
      showCurrentTillAmount : [ {value:this.cashdrawerSettingData.showCurrentTillAmount,disabled:!this.cashdrawerSettingData.active}],
      // enableTillManagement : [ {value:this.cashdrawerSettingData.enableTillManagement,disabled:!this.cashdrawerSettingData.active}],
      reqVerificationTillManagement : [ {value:this.cashdrawerSettingData.reqVerificationTillManagement,disabled:!this.cashdrawerSettingData.active}],
      forceTillManagement : [ {value:this.cashdrawerSettingData.forceTillManagement,disabled:!this.cashdrawerSettingData.active}],
    })
  }

  saveFn(){
    let cashdrawerValue: CashDrawerConfig = {
      // useCashDrawer: this.cashdrawerform.get('useCashDrawer').value,
      openCashDrawerOnCashTransaction: this.cashdrawerform.get('openDrawerOnCashTransaction').value,
      openCashDrawerCashDrop: this.cashdrawerform.get('openDrawerOnCashDrop').value,
      // openCashDrawerOnPayOut: this.cashdrawerform.get('openDrawerOnPayout').value,
      overShortAmount: this.cashdrawerform.get('overShortAmount').value,
      showCurrentTillAmount: this.cashdrawerform.get('showCurrentTillAmount').value,
      // enableTillManagement: this.cashdrawerform.get('enableTillManagement').value,
      reqVerificationTillManagement: this.cashdrawerform.get ('reqVerificationTillManagement').value,
      forceTillManagement: this.cashdrawerform.get ('forceTillManagement').value,
      active: this.cashdrawerform.get('enableCashdrawerForm').value,
      isSaved: true,
    };
    try{
      this._abd.showSpinner()
      this._businessSetupService.saveCashdrawerConfiguration(cashdrawerValue).subscribe(res=>{
        if(res.success){
            this._abd.systemConfigs.cashDrawerConfig.isSaved = true
            this.widgetSaved.emit(true)
          this._connMessageService.showToastMessage(`Cashdrawer settings saved successfully!`, 'success')
          this._businessSetupService.showCashdrawerConfiguration = false
          this._abd.getBusinessConfig().subscribe(res=>{
            if(res.success){
              this._abd.systemConfigs.cashDrawerConfig = res.data.cashDrawerConfig
              this._businessSetupService.initWidget("cashdrawer");
              this._abd.hideSpinner()
            }
          })
        }
      })
    }
    catch{
      this._connMessageService.showToastMessage(`Error saving cashdrawer settings!`, 'error')
      this._abd.hideSpinner()
    }
  }

  cancelButtonClick(){
    this._businessSetupService.showCashdrawerConfiguration = false
  }
  EnableTillManagment()
  {
    if(!this.cashdrawerform.get('enableTillManagement').value)
    {

    this.cashdrawerform.get('reqVerificationTillManagement')?.setValue(false);
    this.cashdrawerform.get('forceTillManagement')?.setValue(false);
    this.cashdrawerform.get('reqVerificationTillManagement')?.disable();
    this.cashdrawerform.get('forceTillManagement')?.disable();
    }
    else
    {
    this.cashdrawerform.get('reqVerificationTillManagement')?.enable();
    this.cashdrawerform.get('forceTillManagement')?.enable();
    }

  }

}
